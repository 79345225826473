import React, { useEffect, useContext } from 'react';

import { AppContext } from '../Context/app-context';
import { ReactComponent as Logo } from '../logo2.svg';
import 'Styles/splash.scss';

const Splash = () => {
  const { showSplash, splashShown } = useContext(AppContext);

  useEffect(() => {
    if (showSplash) {
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    }
  });

  const unhide = () => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
  };
  useEffect(() => {
    if (showSplash) {
      window.scrollTo({ top: 0, behavior: 'instant' });
    }

    const splashEl = document.getElementById('splash');

    splashEl.addEventListener('animationend', () => {
      splashShown();
      unhide();
    });
  });

  const logoCn = ['logo-hero'];
  const splashCn = ['full-screen-splash'];

  if (showSplash) {
    logoCn.push('logo-load');
  } else {
    splashCn.push('hide');
  }

  return (
    <>
      <div style={{ position: 'relative', top: '0px', left: '0px' }}>
        <div
          id='splash'
          className={splashCn.join(' ')}
          style={{ color: 'black' }}
        />
      </div>

      <Logo id='logo' className={logoCn.join(' ')} />
    </>
  );
};

export default Splash;
