/* eslint-disable no-unused-vars */
import React from 'react';

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

const SectionTitle = ({ section, topPadding, bottomPadding }) => {
  const classes = ['section-title', 'text-center'];

  const pt = topPadding ? `pt-${topPadding}` : null;
  const pb = bottomPadding ? `pb-${bottomPadding}` : null;
  if (pt) {
    classes.push(pt);
  } else {
    classes.push('pt-4');
    classes.push('pt-md-5');
  }
  if (pb) {
    classes.push(pb);
  } else {
    classes.push('pb-4');
    classes.push('pb-md-5');
  }

  return (
    <div className={classes.join(' ')}>
      <h2>{section}</h2>
    </div>
  );
};

export default SectionTitle;
