const UserSubMap = {
  nameToSub: {
    bridesmaid: '312b15c0-1061-7015-3cf0-427cf253e34e',
    groomsman: 'f11bc500-90c1-7031-5da9-d7c447f1878f',
    guest: 'b11b2560-6051-70bf-b172-2e8bdf6ff600',
    photobooth: '712be510-70f1-7084-79f5-adccce4209f4'
  },
  subToName: {
    '312b15c0-1061-7015-3cf0-427cf253e34e': 'bridesmaid',
    'f11bc500-90c1-7031-5da9-d7c447f1878f': 'groomsman',
    'b11b2560-6051-70bf-b172-2e8bdf6ff600': 'guest',
    '712be510-70f1-7084-79f5-adccce4209f4': 'photobooth'
  }
};

export default UserSubMap;
