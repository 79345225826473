import React from 'react';

import Carousel from 'react-bootstrap/Carousel';

import Countdown from 'Components/countdown/countdown';

import HeroMobile1 from 'Images/hero/hero1_mobile.jpg';
import HeroMobile2 from 'Images/hero/hero2_mobile.jpg';
import HeroMobile3 from 'Images/hero/hero3_mobile.jpg';
import HeroMobile4 from 'Images/hero/hero4_mobile.jpg';
import HeroMobile5 from 'Images/hero/hero5_mobile.jpg';
import HeroMobile6 from 'Images/hero/hero6_mobile.jpg';
import HeroMobile7 from 'Images/hero/hero7_mobile.jpg';
import HeroMobile8 from 'Images/hero/hero8_mobile.jpg';
import HeroDesk1 from 'Images/hero/hero1_desktop.jpg';
import HeroDesk2 from 'Images/hero/hero2_desktop.jpg';
import HeroDesk3 from 'Images/hero/hero3_desktop.jpg';
import HeroDesk4 from 'Images/hero/hero4_desktop.jpg';
import HeroDesk5 from 'Images/hero/hero5_desktop.jpg';
import HeroDesk6 from 'Images/hero/hero6_desktop.jpg';
import HeroDesk7 from 'Images/hero/hero7_desktop.jpg';
import HeroDesk8 from 'Images/hero/hero8_desktop.jpg';
import HeroDesk9 from 'Images/hero/hero9_desktop.jpg';

import 'Styles/hero.scss';

const DESKTOP_IMGS = [
  HeroDesk1,
  HeroDesk2,
  HeroDesk3,
  HeroDesk4,
  HeroDesk5,
  HeroDesk6,
  HeroDesk7,
  HeroDesk8,
  HeroDesk9
];

const MOBILE_IMGS = [
  HeroMobile1,
  HeroMobile2,
  HeroMobile3,
  HeroMobile4,
  HeroMobile5,
  HeroMobile6,
  HeroMobile7,
  HeroMobile8
];
const Hero = () => {
  const renderCar = (cName, imgs) => (
    <div className={cName}>
      <Carousel indicators={false} interval={4000}>
        {imgs.map((img, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Carousel.Item key={idx}>
            <div style={{ backgroundColor: 'black' }}>
              <img className='car-image' alt='' src={img} />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className='cover' />
      {/* <div className='logo-wrap'>
        <Logo alt='' className='logo d-inline-block align-top ' />
      </div> */}
    </div>
  );

  return (
    <div className='hero-wrap'>
      {renderCar('carousel_mobile', MOBILE_IMGS)}
      {renderCar('carousel_desktop', DESKTOP_IMGS)}
      <Countdown />
    </div>
  );
};

export default Hero;
