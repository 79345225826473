import React, { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAuth } from 'Hooks';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

import Login from 'Components/login/login';
import useEventAuthorizer from 'Hooks/useEventAuthorizer';
import GalleryProvider from 'Context/gallery-context';

const GuestPages = () => {
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const { galleryId } = useParams();
  const [showEventLinkModal, setShowEventLinkModal] = useState(false);
  const { pendingEventSignIn, eventSignInError, tryEventSignIn } =
    useEventAuthorizer();

  useEffect(() => {
    if (!user && galleryId) {
      tryEventSignIn();
    } else {
      const groups =
        user?.signInUserSession.accessToken.payload['cognito:groups'];
      if (groups && groups.includes('admin')) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
      if (user?.fromLink && galleryId) {
        setShowEventLinkModal(true);
      }
    }
  }, [user]);

  return (
    <div>
      <Modal
        show={showEventLinkModal}
        onHide={() => setShowEventLinkModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are now signed in and can upload images from your device
            directly to this gallery.
          </p>
        </Modal.Body>
      </Modal>
      {user ? (
        <GalleryProvider>
          <Outlet context={{ isAdmin }} />
        </GalleryProvider>
      ) : (
        <Container>
          <Row>
            <Col>
              <Alert show={!!eventSignInError} variant='danger'>
                {eventSignInError}
              </Alert>
            </Col>
          </Row>
          <Row className='justify-content-center vh-75'>
            <Col
              className='text-center align-self-center'
              xs={10}
              xl={4}
              lg={6}
              md={8}
            >
              {pendingEventSignIn ? (
                <Spinner />
              ) : (
                <Card>
                  <Card.Body>
                    <Row>
                      <Col className='py-4'>
                        <Card.Text className='brittany font-900 ' as='h3'>
                          Restricted Access
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Login />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default GuestPages;
