import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Login from './login';

const GuestLoginModal = (props) => {
  const { show, onHide } = props;
  const comingSoon = false;

  const closeModal = () => {
    onHide();
  };

  return (
    <Modal
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={show}
      onHide={closeModal}
    >
      <Modal.Header className='text-center' closeButton>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          Guest Access
        </Modal.Title>
      </Modal.Header>
      {comingSoon ? (
        <>
          <Modal.Body className='text-center'>
            <h3 style={{ fontFamily: 'Brittany' }}>Coming Soon</h3>
            <p className='pt-4'>
              Guests who provided their email while submitting their RSVP will
              receive a notification when this feature is ready.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => onHide(false)}>
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body>
            <Login onHide={onHide} />
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <p className='mb-0'>
              Ready to RSVP?{' '}
              <Button
                variant='link'
                onClick={() => onHide(true)}
                className='text-primary fw-bold'
              >
                Start Here
              </Button>
            </p>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default GuestLoginModal;
