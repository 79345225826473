import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import GenericSpinner from 'Components/utility/generic-spinner';
import { useGuests } from 'Hooks';
import RsvpList from './rsvp-list';
import RsvpForm from './rsvp-form';

const RsvpPage = () => {
  const [selectedGuestIdx, setSelectedGuestIdx] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { guests, updateGuest } = useGuests();

  useEffect(() => {
    if (!guests || guests.length < 1) {
      navigate('/');
    }
  }, [guests, navigate]);

  const onSubmitRsvp = async (guest) => {
    setIsLoading(true);
    try {
      await updateGuest(guest, selectedGuestIdx);
      setIsLoading(false);
      setSelectedGuestIdx(-1);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const renderRsvpTing = () => {
    if (selectedGuestIdx < 0) {
      return (
        <RsvpList
          guests={guests}
          onSelect={(idx) => setSelectedGuestIdx(idx)}
        />
      );
    }
    return (
      <RsvpForm
        guest={guests[selectedGuestIdx]}
        onBack={() => setSelectedGuestIdx(-1)}
        onSubmit={(guest) => onSubmitRsvp(guest)}
      />
    );
  };

  return isLoading ? (
    <GenericSpinner isVisible={isLoading} />
  ) : (
    renderRsvpTing()
  );
};

export default RsvpPage;
