/* eslint-disable indent */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef } from 'react';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import {
  faCloudArrowUp,
  faXmark,
  faFilm
} from '@fortawesome/free-solid-svg-icons';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API, graphqlOperation } from 'aws-amplify';

import { createMediaItem } from 'Services/mcwedding-api/graphql/mutations';

import 'Styles/gallery.scss';

const ImageUploader = ({
  galleryId,
  galleryName,
  onError,
  allowMultiple,
  mediaType = 'all'
}) => {
  const [files, setFiles] = useState([]);
  const [successMsg, setSuccessMsg] = useState('');
  const [pendingUpload, setPendingUpload] = useState(false);
  const [loadingFilePreviews, setLoadingFilePreviews] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileUploadRef = useRef();

  const onFileRemove = (idx) => {
    setFiles([...files.slice(0, idx), ...files.slice(idx + 1)]);
  };

  // const generateVideoThumbnail = (file) =>
  //   new Promise((resolve) => {
  //     const canvas = document.createElement('canvas');
  //     const video = document.createElement('video');

  //     // this is important
  //     video.autoplay = true;
  //     video.muted = true;
  //     video.src = URL.createObjectURL(file);

  //     video.onloadeddata = () => {
  //       const ctx = canvas.getContext('2d');

  //       canvas.width = video.videoWidth;
  //       canvas.height = video.videoHeight;

  //       ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  //       video.pause();
  //       return resolve(canvas.toDataURL('image/png'));
  //     };
  //   });

  const renderFilePreviews = () => {
    if (loadingFilePreviews) {
      return <Spinner className='mb-4' />;
    }
    return Array.from(files).map((file, idx) => {
      const type = file.file.type.split('/').shift();

      return (
        <Col key={file.file.name} className='image-preview mx-1 my-1 '>
          {type === 'image' ? (
            <Image
              key={`preview-${file.file.name}`}
              id={`preview-img-${idx}`}
              src={file.image}
              rounded
            />
          ) : (
            <>
              <video
                src={file.image}
                type={file.file.type}
                style={{ backgroundColor: 'black' }}
              >
                <p>Video Preview Not Available</p>
              </video>
              <FontAwesomeIcon className='video-icon' icon={faFilm} />
            </>
          )}
          <button
            type='button'
            className='exit-icon btn-no-style'
            onClick={() => onFileRemove(idx)}
          >
            <FontAwesomeIcon
              className='video-preview'
              size='lg'
              icon={faXmark}
            />
          </button>
        </Col>
      );
    });
  };

  const handleChangeFiles = async (nFiles) => {
    setLoadingFilePreviews(true);
    const promises = Array.from(nFiles).map(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({ file, image: reader.result });
          };
          reader.readAsDataURL(file);
        })
    );
    const results = await Promise.all(promises);
    setFiles([...results]);
    setLoadingFilePreviews(false);
  };

  const clearInput = () => {
    setFiles([]);
    fileUploadRef.value = '';
  };

  const uploadImages = async () => {
    setPendingUpload(true);

    const totalSize = files.reduce((acc, curr) => curr.file.size + acc, 0);

    const promises = files.map(async (fileObj) => {
      const { file } = fileObj;

      const data = {
        name: file.name,
        contentType: file.type,
        galleryId,
        sizeBytes: file.size
      };

      const resp = await API.graphql(
        graphqlOperation(createMediaItem, { input: data })
      );

      const { id } = resp.data.createMediaItem || '';

      const { url, fields } = resp.data.createMediaItem?.uploadUrl || {
        url: '',
        fields: ''
      };
      if (url && fields && id) {
        const parsedFields = JSON.parse(fields);
        const formData = new FormData();

        Object.entries(parsedFields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append('Content-Type', file.type);
        formData.append('x-amz-meta-media-item-id', id);
        formData.append('x-amz-meta-gallery-id', galleryId);
        formData.append('file', file);

        const aResp = await axios.request({
          method: 'post',
          url,
          data: formData,
          onUploadProgress: (p) => {
            const progressPerc = (p.loaded / totalSize) * 100;
            setUploadProgress(progressPerc);
          }
        });

        if (!aResp > 299 || aResp < 200) {
          return Promise.reject(Error(`Failed to upload: ${resp.statusText}`));
        }
      } else {
        Promise.reject(
          Error(
            `Failed to upload. Invalid createMediaItem response: ${JSON.stringify(
              resp.data
            )}`
          )
        );
      }
      return resp;
    });
    let errorStr = '';
    try {
      const results = await Promise.allSettled(promises);
      const rejects = results.filter(({ status }) => status === 'rejected');
      errorStr = rejects.map(({ reason }) => {
        if (typeof reason === 'object') {
          return `${JSON.stringify(reason)}`;
        }

        return reason;
      });
      setPendingUpload(false);
      setUploadProgress(0);
    } catch (error) {
      errorStr = 'Error uploading files';
    }
    if (errorStr.length > 0) {
      console.error(`Error: ${errorStr}`);
      onError(errorStr);
    } else {
      onError('');
      setSuccessMsg(
        `Uploaded ${files.length} files to ${galleryName}.\n It may take a few minutes for your files to appear in the gallery.`
      );
      clearInput();
    }
    setPendingUpload(false);
    setUploadProgress(0);
  };

  let interactionBtnProgress;

  if (pendingUpload) {
    interactionBtnProgress = (
      <ProgressBar className='my-2' striped now={uploadProgress} />
    );
  } else if (files.length > 0) {
    interactionBtnProgress = (
      <Button variant='secondary' className='btn-block' onClick={uploadImages}>
        {pendingUpload ? (
          <Spinner animation='border' role='status' size='sm'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        ) : (
          <>
            <FontAwesomeIcon icon={faCloudArrowUp} className='px-2' />
            Submit {files.length}
          </>
        )}
      </Button>
    );
  } else {
    interactionBtnProgress = (
      <Button
        variant='outline-primary'
        className='btn-block'
        onClick={() => {
          fileUploadRef.current.click();
        }}
      >
        <FontAwesomeIcon icon={faImages} className='px-2' />
        Upload
      </Button>
    );
  }

  const acceptStr =
    // eslint-disable-next-line no-nested-ternary
    mediaType === 'all'
      ? 'image/*,image/heif,image/heic,video/*,video/mp4'
      : mediaType === 'video'
      ? 'video/*,video/mp4'
      : 'image/*,image/heif,image/heic';

  return (
    <>
      <Row className='d-flex justify-content-center my-2'>
        <Col xs={10}>
          <Alert
            style={{ whiteSpace: 'pre-line' }}
            dismissible
            className='text-center mb-0 '
            variant='success'
            show={successMsg.length > 0 && files.length === 0}
            onClose={() => setSuccessMsg('')}
          >
            {successMsg}
          </Alert>
        </Col>
      </Row>
      <Row
        lg={4}
        md={3}
        xs={3}
        className='d-flex justify-content-center align-items-center'
      >
        {renderFilePreviews()}
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col sm={10} xs={10}>
          <div className='d-grid gap-2'>{interactionBtnProgress}</div>
          <input
            type='file'
            ref={fileUploadRef}
            onChange={(e) => handleChangeFiles(e.target.files)}
            multiple={allowMultiple}
            accept={acceptStr}
            className='btn-controlled-file-input'
          />
        </Col>
      </Row>
    </>
  );
};

export default ImageUploader;
