import React from 'react';
import { useLocation } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as Logo } from '../logo.svg';

import 'Styles/footer.scss';

const Footer = () => {
  const { pathname } = useLocation();

  return (
    <div className='footer-wrap'>
      <footer>
        <Container fluid className='page-footer  text-center small'>
          <Row className='py-3 justify-content-md-center justify-content-center'>
            <Col sm={3} className='my-auto d-md-block d-none d-sm-block'>
              <Logo alt='' className='logo d-inline-block align-top' />
            </Col>
            <Col xs={12} sm={4} className='my-auto'>
              {pathname === '/' ? (
                <Row>
                  <Col>
                    <h2>Thank you!</h2>
                  </Col>
                </Row>
              ) : null}
              <Row xs={12} className=''>
                <Col className=' d-flex justify-content-center '>
                  <div className='credits my-auto'>
                    <p>
                      Collaboratively built by the bride and groom using{' '}
                      <a
                        className='gold-link'
                        href='https://react.dev/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        React.js
                      </a>
                      ,{' '}
                      <a
                        className='gold-link'
                        href='https://react-bootstrap.github.io/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        React Bootstrap
                      </a>
                      , and{' '}
                      <a
                        className='gold-link'
                        href='https://docs.amplify.aws/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        AWS Amplify
                      </a>
                      .
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              md={3}
              sm={3}
              xs={12}
              className='contact-section align-items-center my-auto'
            >
              <Row>
                <Col className='d-none d-sm-block'>
                  <h5>Contact Us</h5>
                </Col>
              </Row>
              <Row className='social-links-wrap'>
                <Col className='text-center d-flex justify-content-center'>
                  <div className='social-links '>
                    <a href='mailto: marinocolbert@gmail.com'>
                      <FontAwesomeIcon className='icon' icon={faEnvelope} />
                    </a>
                    <a
                      href='https://www.linkedin.com/in/ben-colbert-35a98331'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FontAwesomeIcon className='icon' icon={faLinkedin} />
                    </a>
                    <a
                      href='https://www.facebook.com/ben.colbert.98'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FontAwesomeIcon className='icon' icon={faFacebook} />
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
