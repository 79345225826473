import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorBoundary = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.error('no page');
    navigate('/', { replace: true });
  }, []);

  return null;
};

export default ErrorBoundary;
