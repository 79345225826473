import React, { createContext, useEffect, useState, useRef } from 'react';

import { API, graphqlOperation } from 'aws-amplify';

import {
  getGallery,
  listGalleries
} from 'Services/mcwedding-api/graphql/queries';

import { deleteMediaItem } from 'Services/mcwedding-api/graphql/mutations';
import * as subscriptions from 'Services/mcwedding-api/graphql/subscriptions';

export const GalleryContext = createContext();

const MAX_LIMIT = 200;

const GalleryProvider = ({ children }) => {
  const [galleries, setGalleries] = useState([]);
  const activeGalleryRef = useRef();
  const [activeGallery, setActiveGallery] = useState({
    name: '',
    id: '',
    token: null
  });
  const nextTokenRef = useRef(null);

  const [activeGalleryMediaItems, setActiveGalleryMediaItems] = useState([]);
  const [error, setError] = useState();
  const [loadingGalleries, setLoadingGalleries] = useState();
  const [loadingGalleryData, setLoadingGalleryData] = useState();

  const getAllMediaUrls = (media) =>
    media.map(({ id, fileName, src, thumbnailSrc, _version, mediaType }) => ({
      thumbnailUrl: `https://d192y1hxhfaqiw.cloudfront.net/${thumbnailSrc}`,
      mediaType,
      url: `https://d192y1hxhfaqiw.cloudfront.net/${src}`,
      name: fileName,
      id,
      _version
    }));

  const getGalleryWithPhotoUrls = async (
    galleryId,
    limit = MAX_LIMIT,
    clearToken = false
  ) => {
    let gallery = {};
    let mediaList = [];
    let errorStr = '';
    try {
      nextTokenRef.current = clearToken ? null : nextTokenRef.current;
      const resp = await API.graphql(
        graphqlOperation(getGallery, {
          id: galleryId,
          limit,
          nextToken: nextTokenRef.current,
          mediaFilter: {
            and: { _deleted: { ne: true }, status: { eq: 'Active' } }
          }
        })
      );

      gallery = resp.data.getGallery;
      const { media } = gallery;
      if (media) {
        mediaList = getAllMediaUrls(media.items);
        nextTokenRef.current = media.nextToken;
      }
    } catch (er) {
      console.error(er);
      errorStr = 'Error retrieving gallery';
    }
    return {
      gallery,
      media: mediaList,
      errorMsg: errorStr
    };
  };

  useEffect(() => {
    const getAllGalleryPreviews = async () => {
      setLoadingGalleries(true);

      const results = await API.graphql(
        graphqlOperation(listGalleries, {
          mediaFilter: {
            and: { _deleted: { ne: true }, status: { eq: 'Active' } }
          }
        })
      );
      const rcvdGalleries = results.data.listGalleries.items;
      const gals = rcvdGalleries.map((gal) => {
        let mediaList = [];
        if (gal.active) {
          mediaList = getAllMediaUrls(gal.media.items.slice(0, 3));
        }
        return {
          ...gal,
          media: [...mediaList]
        };
      });

      setGalleries(gals);
      setLoadingGalleries(false);
    };

    getAllGalleryPreviews();

    const sub1 = API.graphql(
      graphqlOperation(subscriptions.onUpdateMediaItem)
    ).subscribe({
      next: ({ value }) => {
        const mediaItem = value?.data?.onUpdateMediaItem;
        if (mediaItem.galleryId === activeGalleryRef.current) {
          if (mediaItem.status === 'Active') {
            const mediaEntry = {
              mediaType: mediaItem.mediaType,
              thumbnailUrl: `https://d192y1hxhfaqiw.cloudfront.net/${mediaItem.thumbnailSrc}`,
              url: `https://d192y1hxhfaqiw.cloudfront.net/${mediaItem.src}`,
              name: mediaItem.fileName,
              id: mediaItem.id,
              // eslint-disable-next-line no-underscore-dangle
              _version: mediaItem._version
            };

            setActiveGalleryMediaItems((curr) => [mediaEntry, ...curr]);
          }
        }
      },
      error: (er) => console.warn(er)
    });

    return () => {
      sub1.unsubscribe();
    };
  }, []);

  const shouldPaginate = () => !!nextTokenRef.current;

  const paginate = async () => {
    if (!shouldPaginate()) {
      return;
    }

    const { media, errorMsg } = await getGalleryWithPhotoUrls(activeGallery.id);

    if (!errorMsg) {
      setActiveGalleryMediaItems([...activeGalleryMediaItems, ...media]);
    } else {
      setError(errorMsg);
    }
  };

  const loadActiveGallery = async (galleryId, limit = MAX_LIMIT) => {
    const idx = galleries.findIndex((gal) => gal?.id === galleryId);
    if (idx >= 0 && galleryId !== activeGallery?.id) {
      setLoadingGalleryData(true);
      const { gallery, media, errorMsg } = await getGalleryWithPhotoUrls(
        galleryId,
        limit,
        true
      );
      if (errorMsg) {
        setError(errorMsg);
      } else {
        setActiveGallery(gallery);
        activeGalleryRef.current = gallery.id;
        setActiveGalleryMediaItems([...media]);
      }
      setLoadingGalleryData(false);
    }
  };

  const deleteGalleryItem = async (id, _version) => {
    const results = await API.graphql(
      graphqlOperation(deleteMediaItem, { input: { id, _version } })
    );
    return results;
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    paginate,
    galleries,
    activeGallery,
    activeGalleryMediaItems,
    loadingGalleries,
    loadingGalleryData,
    setActiveGallery,
    deleteGalleryItem,
    loadActiveGallery,
    shouldPaginate,
    galleryError: error
  };

  return (
    <GalleryContext.Provider value={value}>{children}</GalleryContext.Provider>
  );
};

export default GalleryProvider;
