import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark
} from '@fortawesome/free-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const RsvpList = ({ guests, onSelect }) => {
  const navigate = useNavigate();
  const [showExitWarning, setShowExitWarning] = useState(false);
  const onCardClicky = (idx) => {
    onSelect(idx);
  };

  const getResponseText = (isAttending) => {
    if (isAttending === undefined || isAttending === '') {
      return 'Awaiting Response';
    }
    return isAttending === 'true' ? 'Accepted' : 'Declined';
  };

  const getResponseIcon = (isAttending) => {
    if (isAttending === undefined || isAttending === '') {
      return faCircleExclamation;
    }
    return isAttending === 'true' ? faCircleCheck : faCircleXmark;
  };

  const createCards = () =>
    guests?.map((guest, idx) => {
      const { mealSel, isAttending, firstName, lastName, middleName, suffix } =
        guest;
      const responseText = getResponseText(isAttending);
      const responseClass = responseText.replace(/\s/g, '').toLowerCase();
      const guestCardClasses = `guest-card ${responseClass}`;
      let displayName = `${firstName} ${lastName}`;
      if (middleName) {
        displayName = `${firstName} ${middleName.charAt(0)} ${lastName}`;
      }
      if (suffix) {
        displayName = `${displayName} ${suffix}`;
      }

      const responseIcon = getResponseIcon(isAttending);

      return (
        <Card
          id={`guest_card_${idx}`}
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          className={guestCardClasses}
          onClick={() => onCardClicky(idx)}
        >
          <Card.Header>
            <Row className='d-flex align-items-center'>
              <Col xs={2} className='float-start'>
                <FontAwesomeIcon
                  className='guest-card__response-icon'
                  icon={responseIcon}
                />
              </Col>
              <Col xs={8}>
                <Card.Text className='guest-card__name text-center'>
                  {displayName}
                </Card.Text>
              </Col>
              <Col />
            </Row>
          </Card.Header>
          <Card.Body className='d-flex flex-column justify-content-center'>
            <Card.Text className='guest-card__header text-center'>
              {responseText}
            </Card.Text>
            <Row>
              {mealSel && isAttending === 'true' ? (
                <Card.Text className='guest-card__meal-selection text-center'>
                  {mealSel}
                </Card.Text>
              ) : null}
            </Row>
          </Card.Body>
        </Card>
      );
    });

  return (
    <>
      <Modal
        backdrop='static'
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={showExitWarning}
        onHide={() => setShowExitWarning(false)}
      >
        <Modal.Body>
          You have not finished responding for all guests. You will need your
          access to code to return to this page.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='me-auto'
            variant='secondary'
            onClick={() => navigate('/')}
          >
            Leave
          </Button>

          <Button
            variant='primary'
            className='float-end'
            onClick={() => setShowExitWarning(false)}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>

      <Row
        xs={1}
        lg={1}
        className='d-flex justify-content-center justify-content-md-center'
      >
        <Col>
          <div className='guest-list'>
            <Stack className='mx-auto mb-4 gap-4 align-content-between' gap={3}>
              {createCards()}
            </Stack>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RsvpList;
