import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import { faPersonWalking, faCarSide } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from 'react-bootstrap';

import 'Styles/hotels.scss';

const HOTEL_LIST = [
  {
    id: 0,
    name: 'Staypineapple',
    rating: 3,
    distanceTimeMin: '10',
    distanceTimeType: 'walk',
    location: 'Boston | South End',
    address: '26 Chandler St, Boston, MA 02116',
    parkingRate: '',
    rate1: 'King: $279-$294',
    rate2: '',
    rateNotice: 'Rates as of 6/3. Code applied to current rate.',
    bookInstructions: 'Use code WED',
    bookLink: 'https://bit.ly/3TJC7Nw',
    imgSrc: 'https://d32slgyj4lxc3m.cloudfront.net/Staypineapple.jpg',
    iosLink: 'https://maps.apple.com/place?auid=6166256791678467025&lsp=9902',
    googleLink: 'https://goo.gl/maps/Fv3vCZxWbqcawYk58',
    badgeType: 'Discount code: WED'
  },
  {
    id: 1,
    name: 'Found Hotel',
    rating: 2,
    distanceTimeMin: '10',
    distanceTimeType: 'walk',
    location: 'Boston | Theatre District',
    address: '78 Charles St S, Boston, MA 02116',
    parkingRate: '',
    rate1: 'Full: $203',
    rate2: 'Queen: $220',
    rate3: 'Double Queen: $237',
    rateNotice: 'Rates as of 6/3. No group rate available.',
    bookInstructions: '',
    bookLink: 'https://bit.ly/40CYo2x',
    imgSrc: 'https://d32slgyj4lxc3m.cloudfront.net/Found_Hotel.jpg',
    badgeType: '',
    iosLink: 'https://maps.apple.com/place?auid=3505317292591031516&lsp=9902',
    googleLink: 'https://goo.gl/maps/rYyF4GFpndeYUSLh7'
  },
  {
    id: 2,
    name: 'Newbury Guest House',
    rating: 3,
    distanceTimeMin: '12',
    distanceTimeType: 'walk',
    location: 'Boston | Back Bay',
    address: '261 Newbury St, Boston, MA 02116',
    parkingRate: 'Daily parking: $30',
    rate1: 'Queen: $240',
    rate2: '',
    rate3: '',
    rateNotice: 'Rates valid until 10/9/2023',
    bookInstructions:
      'Call 1-800-437-7668 and use group code MAR under "Marino group block".',
    bookLink: '',
    imgSrc: 'https://d32slgyj4lxc3m.cloudfront.net/Newbury_Guest_House.jpg',
    badgeType: 'Group Rate',
    iosLink: 'https://maps.apple.com/place?auid=15830861543485579148&lsp=9902',
    googleLink: 'https://goo.gl/maps/9UjjjZoUqpBK36mY6'
  },
  {
    id: 3,
    name: 'Revere Hotel',
    rating: 4,
    distanceTimeMin: '8',
    distanceTimeType: 'walk',
    location: 'Boston | Bay Village',
    address: '200 Stuart St, Boston, MA 02116',
    parkingRate: 'Daily parking: $42',
    rate1: 'Double Queen: $299',
    rate2: '',
    rate3: '',
    rateNotice: 'Rates valid until 10/18/2023',
    bookInstructions: 'Use code MCWRB946',
    bookLink: 'https://bit.ly/44UmFDE',
    imgSrc: 'https://d32slgyj4lxc3m.cloudfront.net/Revere_Hotel.jpg',
    badgeType: 'Group Rate',
    iosLink: 'https://maps.apple.com/place?auid=9916028146091772097&lsp=9902',
    googleLink: 'https://goo.gl/maps/qH3KsYWjatKgeCEk6'
  },
  {
    id: 4,
    name: 'Lenox Hotel',
    rating: 4,
    distanceTimeMin: '10',
    distanceTimeType: 'walk',
    location: 'Boston | Back Bay',
    address: '61 Exeter St, Boston, MA 02116',
    parkingRate: 'Daily parking: $65',
    rate1: 'King: $299',
    rate2: 'Double Queen: $359',
    rate3: '',
    rateNotice: 'Rates valid until 10/9/2023',
    bookInstructions: 'Use code ben110923',
    bookLink: 'https://bit.ly/3LTifE8',
    imgSrc: 'https://d32slgyj4lxc3m.cloudfront.net/Lenox_Hotel.jpg',
    badgeType: 'Group Rate',
    iosLink: 'https://maps.apple.com/place?auid=12828491216724265479&lsp=9902',
    googleLink: 'https://goo.gl/maps/pc89bp4frYQj6DiQ7'
  },
  {
    id: 5,
    name: 'Fairfield Marriott',
    rating: 2,
    distanceTimeMin: '20',
    distanceTimeType: 'drive',
    location: 'Greater Boston | Medford',
    address: '85 Station Landing, Medford, MA 02155',
    parkingRate: 'Daily parking: $12',
    rate1: 'King: $169',
    rate2: 'Double Queen: $179',
    rate3: '',
    rateNotice: 'Rates valid until 10/9/2023',
    bookInstructions: '',
    bookLink: 'https://bit.ly/3ZlMZCr',
    imgSrc: 'https://d32slgyj4lxc3m.cloudfront.net/Fairfield_Medford.jpg',
    badgeType: 'Group Rate',
    iosLink: 'https://maps.apple.com/place?auid=3617124911820623634&lsp=9902',
    googleLink: 'https://goo.gl/maps/WWdUs6miX5qY7WQk8'
  },
  {
    id: 6,
    name: 'Cambria Hotel',
    rating: 3,
    distanceTimeMin: '20',
    distanceTimeType: 'drive',
    location: 'Greater Boston | Somerville',
    address: '515 Somerville Ave, Somerville, MA 02143',
    parkingRate: 'Daily parking: $30',
    rate1: 'King: $189',
    rate2: 'Double Queen: $189',
    rate3: '',
    rateNotice: 'Rates valid until 10/9/2023',
    bookInstructions: '',
    bookLink: 'https://bit.ly/3lGiJ7C',
    imgSrc: 'https://d32slgyj4lxc3m.cloudfront.net/Cambria_Somerville.jpg',
    badgeType: 'Group Rate',
    iosLink: 'https://maps.apple.com/place?auid=12611789961326485007&lsp=9902',
    googleLink: 'https://goo.gl/maps/pLMPRCsYLQ1FpqbJ9'
  },
  {
    id: 7,
    name: 'Hampton Inn & Suites',
    rating: 2,
    distanceTimeMin: '15',
    distanceTimeType: 'drive',
    location: 'Greater Boston | Watertown',
    address: '25 Bond St, Watertown, MA 02472',
    parkingRate: 'Daily parking: $28',
    rate1: 'King: $189',
    rate2: 'Double Queen: $229',
    rate3: '',
    rateNotice: 'Rates valid until 10/13/2023',
    bookInstructions: '',
    bookLink: 'https://bit.ly/42IdxRf',
    imgSrc:
      'https://d32slgyj4lxc3m.cloudfront.net/Hampton_by_Hilton_Watertown.jpg',
    badgeType: 'Group Rate',
    iosLink: 'https://maps.apple.com/place?auid=12763351161168476688&lsp=9902',
    googleLink: 'https://goo.gl/maps/eeN3im6UHPLRECpC7'
  },
  {
    id: 8,
    name: 'Embassy Suites',
    rating: 3,
    distanceTimeMin: '15',
    distanceTimeType: 'drive',
    location: 'Boston | Logan Airport',
    address: '207 Porter St, Boston, MA 02128',
    parkingRate: 'Daily parking: $60',
    rate1: 'King: $210',
    rate2: 'Double Queen: $210',
    rate3: '',
    rateNotice: 'Rates valid until 10/10/2023',
    bookInstructions: '',
    bookLink: 'https://bit.ly/3pwln1f',
    imgSrc:
      'https://d32slgyj4lxc3m.cloudfront.net/Embassy_Suites_Logan_Airport.jpg',
    badgeType: 'Group Rate',
    iosLink: 'https://maps.apple.com/place?auid=11418242992172143655&lsp=9902',
    googleLink: 'https://goo.gl/maps/SZnWj8BhvSPwLEmP7'
  }
];
const Hotels = (props) => {
  const { isIos } = props;

  const renderHotels = () =>
    HOTEL_LIST.map((hotel) => {
      const commuteIcon =
        hotel.distanceTimeType === 'drive' ? faCarSide : faPersonWalking;

      return (
        <Col key={hotel.id} lg={4} md={6} sm={12}>
          <Card className='my-3'>
            <div style={{ position: 'relative' }}>
              <Badge
                pill
                style={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  fontSize: '.9rem',
                  color: 'black'
                }}
                bg='warning'
              >
                {hotel.badgeType}
              </Badge>

              <Card.Img src={hotel.imgSrc} />
            </div>
            <Card.Body className='hotel-card'>
              <Card.Title>
                <Row className='align-items-center'>
                  <Col style={{ fontWeight: 'bold' }} xs={7}>
                    {hotel.name}
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'right'
                    }}
                    xs={5}
                  >
                    <span style={{ fontSize: '1rem' }}>
                      {hotel.rating}-star hotel
                    </span>
                  </Col>
                </Row>
              </Card.Title>
              <Row>
                <Col xs={7}>
                  <div>
                    <Card.Text>
                      <span
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        Rates
                      </span>{' '}
                      <br />
                      {hotel.rate1}
                      {hotel.rate1 ? <br /> : null}
                      {hotel.rate2} {hotel.rate2 ? <br /> : null}
                      {hotel.rate3} {hotel.rate3 ? <br /> : null}
                      {hotel.parkingRate}
                      {hotel.parkingRate ? <br /> : null}
                    </Card.Text>
                  </div>
                </Col>
                <Col
                  xs={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'right'
                  }}
                >
                  <div
                    // style={{ border: '1px solid black' }}
                    className='text-right'
                  >
                    <Card.Text>
                      <span style={{ fontWeight: 'bold' }}>
                        Distance to Venue
                      </span>
                      <br />
                      <FontAwesomeIcon
                        style={{ paddingRight: '10px' }}
                        icon={commuteIcon}
                      />
                      {hotel.distanceTimeMin} min
                    </Card.Text>
                  </div>
                </Col>
              </Row>
              <Row>
                <Card.Text>
                  <span style={{ fontWeight: 'bold' }}>Location</span> <br />
                  {hotel.location}
                  <br />
                  <a
                    target='_blank'
                    href={isIos ? hotel.iosLink : hotel.googleLink}
                    rel='noreferrer'
                  >
                    {hotel.address}
                  </a>
                </Card.Text>
              </Row>
              {hotel.bookLink ? (
                <Row>
                  <Col xs={6}>
                    <Button href={hotel.bookLink} target='_blank'>
                      Book Now
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Card.Text className='instructions'>
                    {hotel.bookInstructions}
                  </Card.Text>
                </Row>
              )}
            </Card.Body>

            <Card.Footer>
              <small>{hotel.rateNotice}</small>
            </Card.Footer>
          </Card>
        </Col>
      );
    });
  return (
    <div id='hotel-section' className='page-section pb-5'>
      <Container>
        <Row className='justify-content-center text-center'>
          <Col className='py-3'>
            The links below will grant access to the room blocks with discounted
            rates. Group rates are valid until the specified cutoff date.
          </Col>
        </Row>
        <Row>{renderHotels()}</Row>
      </Container>
    </div>
  );
};

export default Hotels;
