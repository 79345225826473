import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import 'Styles/welcome.scss';

const Welcome = ({ comingSoon }) => (
  <div className={comingSoon ? 'welcome-area' : 'welcome-area page-section'}>
    <Container className='welcome-content'>
      {comingSoon ? (
        <div className='item'>
          <Row>
            <Col>
              <h2 className='coming-soon-header'>Coming Soon</h2>
            </Col>
          </Row>
        </div>
      ) : null}
      <div className='item'>
        <Row className='py-3'>
          <Col>
            <h2>
              November 10, <br className='save-date-break' />
              2023
            </h2>
          </Col>
        </Row>
        <Row className='pb-3'>
          <Col>
            <h4>
              We&apos;re getting married! We can&apos;t wait to celebrate with
              you.
            </h4>
          </Col>
        </Row>
      </div>
      {!comingSoon ? (
        <div className='item'>
          <Row className='py-3'>
            <Col>
              <Button
                size='lg'
                variant='outline-primary'
                style={{ color: 'white', fontWeight: 'bold' }}
                as={Link}
                to='/rsvp'
              >
                RSVP Now
              </Button>
            </Col>
          </Row>
        </div>
      ) : null}
      <div className='item'>
        <Row className='py-3'>
          <Col>
            <div className='hashtag'>#4ThousandMilesToTheAisle</div>
          </Col>
        </Row>
      </div>
    </Container>
  </div>
);

export default Welcome;
