import React from 'react';
import { DateUtils } from 'Utils';

const EventDate = (props) => {
  const { date, title } = props;
  const dayOfWeek = DateUtils.getDayName(date, 'long');
  const month = DateUtils.getMonthName(date, 'short');
  const day = DateUtils.getDayStr(date).trim();

  const year = date.getYear() + 1900;
  return (
    <div className='d-flex justify-content-center'>
      <div className='event-date'>
        <h3 className='dow'> {dayOfWeek}</h3>
        <div className='mdy'>
          <div className='item'>
            <span>{month}</span>
          </div>
          <div className='item day'>
            <span>{day}</span>
          </div>
          <div className='item '>
            <span>{year}</span>
          </div>
        </div>
        <h3 className='title'> {title}</h3>
      </div>
    </div>
  );
};

export default EventDate;
