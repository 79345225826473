import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const GuideLinks = {
  bridesmaid:
    'https://mc-wedding-app-guides.s3.us-east-2.amazonaws.com/bridesmaid-guide.pdf'
};

const Guides = () => {
  const params = useParams();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (params && params.guideId) {
  //     const { guideId } = params;
  //     if (guideId in GuideLinks) {
  //       const url = GuideLinks[guideId];
  //       fetch(url).then((response) => {
  //         response.blob().then((blob) => {
  //           // Creating new object of PDF file
  //           const fileURL = window.URL.createObjectURL(blob);
  //           // Setting various property values
  //           const alink = document.createElement('a');
  //           alink.href = fileURL;
  //           alink.download = 'BridesMaid_Guide.pdf';
  //           // alink.click();
  //         });
  //       });
  //     }
  //   }
  // });

  let url = '';
  if (params && params.guideId) {
    const { guideId } = params;
    if (guideId in GuideLinks) {
      url = GuideLinks[guideId];
    }
  }

  return url ? (
    <div style={{ height: '100vh' }}>
      <iframe
        title='Bridesmaid'
        style={{ height: '100%', width: '100%' }}
        src={url}
      />
    </div>
  ) : (
    <Navigate to='/' />
  );
};
export default Guides;
