import React, { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import 'Styles/rsvp.scss';

const ProgessActions = Object.freeze({
  submit: 'Submit',
  next: 'Next',
  skip: 'Skip'
});

const MealSides = Object.freeze({
  beef: 'Potato Puree, Brussel Sprouts, Red Wine Sauce *gluten free',
  chicken: 'Mushroom, Onion & Herbed Bread Pudding, Heirloom Carrots',
  vegetarian:
    'Roasted Spaghetti Squash, Cashew Aioli, Spiced Cranberry Relish *gluten free'
});

const RsvpForm = (props) => {
  const { guest, onBack, onSubmit } = props;
  const [collapseForm, setCollapseForm] = useState(
    guest?.isAttending === 'false' || false
  );
  const [stepCtrl, setStepCtrl] = useState({
    numSteps: 5,
    currentStep: 0,
    stepsEnabled: !('isAttending' in guest) || guest.isAttending === 'true'
  });
  const [formFields, setFormFields] = useState({ ...guest });
  const [validated, setValidated] = useState(false);

  const getProgressAction = () => {
    if (stepCtrl.currentStep === 3 && formFields.email === '') {
      return ProgessActions.skip;
    }
    if (
      stepCtrl.numSteps - 1 === stepCtrl.currentStep ||
      !stepCtrl.stepsEnabled
    ) {
      return ProgessActions.submit;
    }
    return ProgessActions.next;
  };

  const onAttendingSelection = ({ target: { value } }) => {
    if (value === 'true') {
      setStepCtrl({ ...stepCtrl, stepsEnabled: true });
    } else {
      setStepCtrl({ ...stepCtrl, stepsEnabled: false });
      setValidated(false);
    }
    setFormFields({ ...formFields, isAttending: value });
    setCollapseForm(value === 'false');
  };

  const onNextOrSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else if (getProgressAction() === ProgessActions.submit) {
      onSubmit({ ...guest, ...formFields });
    } else {
      setValidated(false);
      setStepCtrl({
        ...stepCtrl,
        currentStep: stepCtrl.currentStep + 1
      });
    }
  };

  const onPrevious = () => {
    if (stepCtrl.currentStep === 0) {
      onBack();
    } else {
      setValidated(false);
      setStepCtrl({
        ...stepCtrl,
        currentStep: stepCtrl.currentStep - 1
      });
    }
  };

  const messageStep = (
    <div>
      <Row>
        <Col>
          <Form.Group className='mb-3' controlId='formGroupGuestMessage'>
            <Form.Label>Leave us a message</Form.Label>
            <Form.Control
              className='w-100'
              as='textarea'
              onChange={({ target: { value } }) =>
                setFormFields({ ...formFields, message: value })
              }
              value={formFields.message}
              placeholder=''
              maxLength='250'
            />
            <Form.Text muted>
              {`${formFields.message?.length || 0}/250`}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );

  const emailStep = (
    <div>
      <Row>
        <Col>
          <Form.Text>Drop your email so we can keep in touch.</Form.Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className='my-3' controlId='formGroupEmail'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              onChange={({ target: { value } }) => {
                if (value === '') {
                  setValidated(false);
                }
                setFormFields({ ...formFields, email: value });
              }}
              value={formFields.email}
            />
            <Form.Control.Feedback type='invalid'>
              Email invalid.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
  const customYesPrompt =
    guest?.customYesPrompt !== '' ? guest?.customYesPrompt : 'Yes';

  const customNoPrompt =
    guest?.customNoPrompt !== '' ? guest?.customNoPrompt : 'No';

  const attendingStep = (
    <div>
      <Row>
        <Col md={6} xs={12}>
          <Form.Group className='mb-3' controlId='formGroupFirstName'>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              required
              onChange={({ target: { value } }) =>
                setFormFields({ ...formFields, firstName: value })
              }
              value={formFields.firstName}
              placeholder='Victoria'
            />
            <Form.Control.Feedback type='invalid'>
              First name required.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='mb-3' controlId='formGroupLastName'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              required
              onChange={({ target: { value } }) =>
                setFormFields({ ...formFields, lastName: value })
              }
              value={formFields.lastName}
              placeholder='Marino'
            />
            <Form.Control.Feedback type='invalid'>
              Last name required.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className='mb-3' controlId='formGroupIsAttending'>
            <Form.Label>Attending</Form.Label>
            <Form.Select
              required
              onChange={(e) => onAttendingSelection(e)}
              value={formFields.isAttending || ''}
            >
              <option disabled hidden value=''>
                Choose...
              </option>
              <option value>{customYesPrompt}</option>
              <option value={false}>{customNoPrompt}</option>
            </Form.Select>
            <Form.Control.Feedback type='invalid'>
              Please select an RSVP response.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Collapse in={collapseForm}>
        <Row>
          <Col className='mt-4'>
            <Form.Group className='mb-3' controlId='formGroupGuestMessage'>
              <Form.Label>
                We&apos;re sorry you won&apos;t be joining us. Feel free to
                leave us a message.
              </Form.Label>
              <Form.Control
                as='textarea'
                onChange={({ target: { value } }) =>
                  setFormFields({ ...formFields, message: value })
                }
                value={formFields.message}
                placeholder=''
                maxLength='250'
              />
              <Form.Text muted>
                {`${formFields.message?.length || 0}/250`}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
      </Collapse>
    </div>
  );

  const mealSelText = formFields.mealSel ? (
    <span>
      <span>Sides:</span> {MealSides[formFields.mealSel]}
    </span>
  ) : (
    'Select option to see sides'
  );
  const mealSelStep = (
    <div>
      <Row>
        <Col>
          <Form.Group className='mb-3' controlId='formGroupMealSel'>
            <Form.Label>Meal Option</Form.Label>
            <Form.Select
              required
              onChange={({ target: { value } }) =>
                setFormFields({ ...formFields, mealSel: value })
              }
              value={formFields.mealSel || ''}
            >
              <option disabled hidden value=''>
                Choose...
              </option>
              <option value='chicken'>Chicken Breast Medallions</option>
              <option value='beef'>
                Petit Filet Mignon &amp; Braised Short Rib
              </option>
              <option value='vegetarian'>
                Swiss Chard, Quinoa &amp; Black Bean Cake (Vegetarian)
              </option>
            </Form.Select>
            <Form.Text className='my-3'>{mealSelText}</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className='mb-3' controlId='formGroupDietaryRestrictions'>
            <Form.Label>Dietary restrictions</Form.Label>
            <Form.Control
              as='textarea'
              maxLength='150'
              onChange={({ target: { value } }) =>
                setFormFields({ ...formFields, dietaryRestrictions: value })
              }
              value={formFields.dietaryRestrictions}
              placeholder='Allergies, etc.'
            />
            <Form.Text muted>
              {`${formFields.dietaryRestrictions?.length || 0}/150`}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className='mb-3' controlId='formGroupSpecialRequest'>
            <Form.Label>Additional requests</Form.Label>

            <Form.Control
              as='textarea'
              maxLength='150'
              onChange={({ target: { value } }) =>
                setFormFields({ ...formFields, specialRequest: value })
              }
              value={formFields.specialRequest}
              placeholder='Accessibility accomdations, etc.'
            />
            <Form.Text muted>
              {`${formFields.specialRequest?.length || 0}/150`}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );

  const eventStep = (
    <div>
      <Row>
        <Col>
          <h6 className='mb-4'>
            In addition to the ceremony and reception on Friday Nov 10, do you
            plan to attend...
          </h6>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Form.Group className='mb-3' controlId='welcomeDrinksSelect'>
            <Form.Label className=''>
              Welcome Drinks - Wednesday Nov 8 | 6:30pm to 8:30pm
            </Form.Label>

            <Form.Select
              required
              onChange={({ target: { value } }) =>
                setFormFields({ ...formFields, attendingWelcomeDrinks: value })
              }
              value={formFields.attendingWelcomeDrinks || ''}
            >
              <option disabled hidden value=''>
                Choose...
              </option>
              <option value='yes'>Yes</option>
              <option value='no'>No</option>
              <option value='maybe'>Maybe</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      {guest.rehearsalDinnerAttendee === 'true' ? (
        <Row>
          <Col className='my-2'>
            <Form.Group className='mb-3' controlId='welcomeDrinksSelect'>
              <Form.Label>
                Rehearsal Dinner - Thursday Nov 9 | 7:30pm
              </Form.Label>
              <Form.Select
                required
                onChange={({ target: { value } }) =>
                  setFormFields({ ...formFields, attendingRehearsal: value })
                }
                value={formFields.attendingRehearsal || ''}
              >
                <option disabled hidden value=''>
                  Choose...
                </option>
                <option value='yes'>Yes</option>
                <option value='no'>No</option>
                <option value='maybe'>Maybe</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col sm={12}>
          <Form.Group className='mb-3' controlId='afterPartySelect'>
            <Form.Label>
              Post-Reception After Party - Friday Nov 10 | 9:45pm to 12am
            </Form.Label>
            <Form.Select
              required
              onChange={({ target: { value } }) =>
                setFormFields({ ...formFields, attendingAfterParty: value })
              }
              value={formFields.attendingAfterParty || ''}
            >
              <option disabled hidden value=''>
                Choose...
              </option>
              <option value='yes'>Yes</option>
              <option value='no'>No</option>
              <option value='maybe'>Maybe</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Form.Group className='mb-3' controlId='brunchsSelect'>
            <Form.Label>Brunch - Saturday Nov 11 | 10:00am to 12pm</Form.Label>
            <Form.Select
              required
              onChange={({ target: { value } }) =>
                setFormFields({ ...formFields, attendingBrunch: value })
              }
              value={formFields.attendingBrunch || ''}
            >
              <option disabled hidden value=''>
                Choose...
              </option>
              <option value='yes'>Yes</option>
              <option value='no'>No</option>
              <option value='maybe'>Maybe</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Form.Text>
            More information about each event can be found on our{' '}
            <Link to='/#events-section?nosplash' target='_blank'>
              Events Page
            </Link>
          </Form.Text>
        </Col>
      </Row>
    </div>
  );

  const formSteps = [
    attendingStep,
    mealSelStep,
    eventStep,
    emailStep,
    messageStep
  ];

  const nextOrSubmitAction = getProgressAction();
  const nextOrSubmitVariant =
    nextOrSubmitAction === ProgessActions.skip ? 'light' : 'primary';

  return (
    <Card className='mb-3 rsvp-form-card'>
      <Card.Body>
        <Form
          noValidate
          validated={validated}
          id='rsvp-form'
          onSubmit={onNextOrSubmit}
        >
          <Row xs={1} lg={1}>
            <Col>
              {formSteps[stepCtrl.currentStep]}
              <Row className='mt-4'>
                <Col xs={4} lg={4}>
                  <Button
                    className='float-start'
                    variant='secondary'
                    onClick={onPrevious}
                  >
                    Previous
                  </Button>
                </Col>
                <Col>
                  <Button
                    className='float-end'
                    type='submit'
                    variant={nextOrSubmitVariant}
                  >
                    {getProgressAction()}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default RsvpForm;
