import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import leviImg from 'Images/crew/levi.jpg';
import drewImg from 'Images/crew/drew.jpg';
import mattImg from 'Images/crew/matt.jpg';
import brodyImg from 'Images/crew/brody.jpg';
import usmanImg from 'Images/crew/usman.jpg';
import johnImg from 'Images/crew/john.jpg';
import averyImg from 'Images/crew/avery.jpg';
import maureenImg from 'Images/crew/maureen.jpg';
import juliannaImg from 'Images/crew/julianna.jpg';
import jessImg from 'Images/crew/jess.jpg';
import nicoleImg from 'Images/crew/nicole.jpg';
import morganImg from 'Images/crew/morgan.jpg';
import carlyImg from 'Images/crew/carly2.jpg';
import vic from 'Images/crew/vic.jpg';
import ben from 'Images/crew/ben.jpg';

import SectionTitle from 'Components/utility/section-title';

import 'Styles/crew.scss';

const Crew = () => (
  <div id='crew-section' className='page-section'>
    <SectionTitle section='The Crew' />
    <Container>
      <Row className='d-flex justify-content-center'>
        <Col xs={12} lg={8}>
          <div className='person-area-menu'>
            <div className='person-btn'>
              <Tabs id='crew-tabs' defaultActiveKey='couple' className='mb-3'>
                <Tab eventKey='couple' title='Bride &amp; Groom'>
                  <div id='bride-groom-wrap' className='Groomsman-wrap'>
                    <Row className='justify-content-center'>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={vic} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Victoria Marino</h3>

                            <span className='title'>ft. Maisel</span>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={ben} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Benjamin Colbert</h3>
                            <span className='title'>ft. Oliver</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey='bridesmaids' title='Bridesmaids'>
                  <div className='bridesmaids-wrap'>
                    <Row>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={jessImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Jessica LeGrand</h3>
                            <span className='title'>Matron of Honor</span>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={juliannaImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Julianna Wessels</h3>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={averyImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Avery Stroman</h3>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={nicoleImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Nicole Quezada</h3>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={carlyImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Carly Vogel</h3>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={maureenImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Maureen Templeman</h3>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 6, offset: 3 }}
                        xs={{ span: 6, offset: 3 }}
                      >
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={morganImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Morgan Breen</h3>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey='groomsmen' title='Groomsmen'>
                  <div className='groomsmen-wrap'>
                    <Row>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={leviImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Levi Boyer</h3>
                            <span className='title'>Best Man</span>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={drewImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Andrew Jemmott</h3>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={brodyImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Grayson Brody</h3>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={mattImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Matt Marino</h3>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={johnImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>John Diaconu</h3>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={6}>
                        <div className='person-wrap'>
                          <div className='person-img'>
                            <img src={usmanImg} alt='' />
                          </div>
                          <div className='person-content'>
                            <h3>Usman Islam</h3>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Crew;
