import React, { createContext, useMemo, useState } from 'react';
import { API } from 'aws-amplify';

const API_NAME = 'guestApi';
const BASE_PATH = '/guest';
export const GuestContext = createContext();

export const GuestProvider = ({ children }) => {
  const [guests, setGuests] = useState([]);
  const [adminGuestList, setAdminGuestList] = useState([]);

  const fetchGuests = async (inviteCode, lastName) => {
    const myInit = {
      headers: {},
      queryStringParameters: {
        lastName
      }
    };
    try {
      const data = await API.get(
        API_NAME,
        `${BASE_PATH}/${inviteCode}`,
        myInit
      );
      if (data && data.length) {
        setGuests(data);
      }
      return data;
    } catch (err) {
      console.error(err?.response?.data?.error);
      throw err?.response?.data?.error;
    }
  };

  const updateGuest = async (guest, idx) => {
    const myInit = {
      headers: {},
      body: { ...guest }
    };
    try {
      const resp = await API.put(API_NAME, BASE_PATH, myInit);
      const tmpGuests = [...guests];
      tmpGuests[idx] = guest;
      setGuests(tmpGuests);
      return resp;
    } catch (err) {
      console.error(err?.response?.data?.error);
      throw err?.response?.data?.error;
    }
  };

  const adminFetchAllGuests = async () => {
    const myInit = {
      headers: {}
    };
    try {
      const data = await API.get(API_NAME, `${BASE_PATH}`, myInit);
      if (data && data.length) {
        setAdminGuestList(data);
      }
      return data;
    } catch (err) {
      console.error(err?.response?.data?.error);
      throw err?.response?.data?.error;
    }
  };

  const value = useMemo(
    () => ({
      guests,
      adminGuestList,
      updateGuest,
      fetchGuests,
      adminFetchAllGuests,
      numResponses: guests.reduce((acc, curr) => acc + !!curr.isAttending, 0)
    }),
    [guests]
  );

  return (
    <GuestContext.Provider value={value}>{children}</GuestContext.Provider>
  );
};
