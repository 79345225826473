import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SectionTitle from 'Components/utility/section-title';

import story1 from 'Images/story/We_met_plain.png';
import story2 from 'Images/story/Ben_moved.png';
import story3 from 'Images/story/V_visited_B.png';
import story4 from 'Images/story/girlfriend2.png';
import story5 from 'Images/story/long_distance.png';
import story6 from 'Images/story/lockdown.png';
import story7 from 'Images/story/Move_to_Boston.png';
import story8 from 'Images/story/Ben_proposed.png';
import { ReactComponent as BenProposedIcon } from 'Images/story/Ben_Proposed.svg';
import { ReactComponent as CovidTravelRestrictionsIcon } from 'Images/story/Covid_Travel_Restrictions.svg';
import { ReactComponent as ILoveYouIcon } from 'Images/story/I_Love_You.svg';
import { ReactComponent as MeetMeInIcon } from 'Images/story/Meet_Me_In.svg';
import { ReactComponent as VictoriaVisitedBenIcon } from 'Images/story/Victoria_Visited_Ben.svg';
import { ReactComponent as WeMetIcon } from 'Images/story/We_Met.svg';
import { ReactComponent as WeMovedToBostonIcon } from 'Images/story/We_Moved_To_Boston.svg';
import { ReactComponent as WeSaidGoodbyeIcon } from 'Images/story/We_Said_Goodbye.svg';

import 'Styles/story.scss';

const Story = () => {
  const TIMELINE_CONTENT = [
    {
      date: 'June 3, 2017',
      title: 'We met',
      image: story1,
      icon: <WeMetIcon className='story-icon-img' />,
      storyContent: (
        <p>
          Yep, that&apos;s really what our dating app bios said. After a few
          weeks of chatting, we met for brunch at The Mill in downtown St. Pete.
          After brunch, we spent the next five hours visiting local breweries,
          browsing an antique market, and spotting street art. A strange guy on
          a bike rode past us, saying, &quot;Hey man, that&apos;s your wife.
          That&apos;s your WIFE&quot;. We laughed it off; this was{' '}
          <span style={{ fontStyle: 'italic' }}>barely</span> a date. It was
          more like a friendly hang out. From the start, we knew that Ben had
          accepted a new job at Amazon in London and was moving in less than 3
          months. We knew a long-term relationship was off the table (ha!) but
          had enjoyed talking so much that we wanted to meet. Over the next few
          months, we saw each other as often as we could. We went to an escape
          room, Barnes and Noble, Top Golf, and a board game café, out to
          dinner, in for Thai takeout and a movie, and more. Our time together
          was easy, exciting, and entirely platonic... until a few weeks before
          Ben was set to leave, when we shared a first kiss (whoops).
        </p>
      )
    },
    {
      date: 'Aug 22, 2017',
      title: 'Ben moved to London',
      image: story2,
      icon: <WeSaidGoodbyeIcon className='story-icon-img' />,
      storyContent: (
        <p>
          We said goodbye the day before Ben left, in the parking lot of a
          Cracker Barrel, where we had met for breakfast. We kept things light
          &ndash; &quot;Have the most amazing time in London&quot;… &quot;Good
          luck this semester”… &quot;I&apos;ll miss you”. We were sad but
          decidedly against a 4,000 mile long-distance relationship involving a
          6-hour time difference. Within a couple months, we each went on first
          dates with new people. But neither of those first dates turned into
          second dates, and our connection only grew stronger. As Ben navigated
          an international career transition and Victoria pursued her PhD, we
          became constant supports for one another, even if just over the phone.
        </p>
      )
    },
    {
      date: 'Dec 7, 2017',
      title: 'Victoria visited Ben',
      image: story3,
      icon: <VictoriaVisitedBenIcon className='story-icon-img' />,
      storyContent: (
        <p>
          To Victoria&apos;s surprise, no one tried to talk her out of a 2-week
          international trip to visit a man she&apos;d known in-person for only
          a few months. Instead, while dropping her off at the airport, her dad
          took her face between his hands and said, &quot;Be safe and smart
          about where you go and what you do&quot;. Her mom said &quot;Have fun!
          Make it an experience you can look back on when you&apos;re 50&quot;.
          <br />
          <br />
          For the first week in London, Victoria worked from coffee shops while
          Ben worked from the office. At night, Ben showed Victoria around the
          city and introduced her to friends. We ate at what would become our
          favorite restaurant, Dishoom, and randomly went to a Marilyn Manson
          concert. We spent the second week in Florence and Rome, where we
          climbed cathedrals, visited art galleries and consumed our weight in
          pasta, wine, and gelato. More importantly, we did
          &ldquo;ordinary&rdquo; stuff: woke up together, went grocery shopping,
          made coffee and cheese and onion omelettes, raced up flights of
          stairs, held hands. More than just a fun time to remember when
          we&apos;re 50, the trip made it perfectly clear: we weren&apos;t
          quitting each other any time soon.
        </p>
      )
    },
    {
      date: 'Feb 14/15, 2018',
      title: (
        <span style={{ fontStyle: 'italic' }}>
          &ldquo;I love you. Will you be my girlfriend?&rdquo;
        </span>
      ),
      image: story4,
      icon: <ILoveYouIcon className='story-icon-img' />,
      storyContent: (
        <p>
          Practicality and orders of operation? We don&apos;t know them. Our
          long-distance relationship &ldquo;officially&rdquo; started on
          February 14th or 15th, depending on which side of the Atlantic you
          were on.
        </p>
      )
    },
    {
      date: 'Feb 2018 - Feb 2020',
      title:
        'Meet me in... London ✈ Ireland ✈ Croatia ✈ Tampa ✈ Boston ✈ Sweden...',
      image: story5,
      icon: <MeetMeInIcon className='story-icon-img' />,
      storyContent: (
        <p>
          Two years of the greatest adventures. Victoria made five more trips to
          London. Ben scheduled detours during stateside travel for work. We met
          up in Ireland to explore Dublin and Galway, coincidentally at the same
          time that Victoria&apos;s friend Avery was visiting! Ben surprised
          Victoria with an aging conference in Sweden where, in between
          sessions, we walked through/explored parks, sightsaw via a boat
          cruise, hiked with goats, and visited a carnival with giant food and
          candy prizes. In Croatia, we kayaked Plitvice Lakes National Park, and
          made it back to Zagreb just in time to see people gathered in squares
          to watch Croatia head off France in the World Cup. We brought each
          other home - to Ben&apos;s family in Tampa/St. Pete and to
          Victoria&apos;s family in Boston. Each reunion started with
          unimaginable anticipation and some awkwardness as we reconciled the
          fact that the familiar voice over the phone was the person standing in
          front of us, complete with less familiar body language and resting
          facial expressions. Without fail, the awkwardness quickly faded and we
          fell back in love each time.
        </p>
      )
    },
    {
      date: 'March 2020',
      title: 'COVID, travel restrictions, and 9 months apart',
      image: story6,
      icon: <CovidTravelRestrictionsIcon className='story-icon-img' />,
      storyContent: (
        <p>
          Nine months without a visit. It was the longest we&apos;d been apart,
          and amid the overwhelming loss and uncertainty during that time, we{' '}
          <span style={{ fontStyle: 'italic' }}>
            just wanted to be together
          </span>
          . In some ways, it was the push we needed to focus on closing the
          distance. Victoria concentrated on completing her PhD and searching
          for research positions. Ben started interview prep and his own job
          hunt. We hung on.
        </p>
      )
    },
    {
      date: 'June 1, 2021',
      title: 'We moved to Boston',
      image: story7,
      icon: <WeMovedToBostonIcon className='story-icon-img' />,
      storyContent: (
        <p>
          The light at the end of the long distance tunnel came when we both
          secured jobs in Boston. Soon after, following weeks of largely
          unsuccessful remote apartment hunting, we signed the lease to our
          apartment based only on a Zoom tour with the realtor. Ben flew to
          Boston on May 30th. We drove to the apartment on June 1st and were
          relieved to find that, although the hardwood floors were squeakier
          than expected, it didn&apos;t smell like fish and was everything we
          wanted in a first apartment together. Later, we discovered that the
          train station across the street that Ben uses to commute to work sits
          on Colbert St. and is named Bellevue - the name of the street on which
          Victoria&apos;s dad grew up. In July, Victoria and Oliver (our cat)
          made their final move from Tampa. It took us some time to
          learn/re-learn how to parallel park, and, you know... adjust to the
          tsunami of other life changes. But we were together, and not just for
          a couple weeks! It felt like the culmination of our commitment and
          patience over the past few years. We spent the next couple years
          building the life and home we&apos;d always imaged.
        </p>
      )
    },
    {
      date: 'Oct 27, 2022',
      title: 'Ben proposed',
      image: story8,
      icon: <BenProposedIcon className='story-icon-img' />,
      storyContent: (
        <p>
          Well, he got COVID and we quarantined from each other for five days
          first. But THEN... Ben proposed! On our last day in Florence! A city
          that, since our trip there in 2017, had become so very important to
          us. Sure, the trip went differently than planned; we cancelled the
          five-day Southern Italy portion of our itinerary and hunkered down in
          Florence while Ben was sick. Still, we had three amazing days in
          Venice with day trips to Murano and Burano. We enjoyed walks, some
          outdoor meals, and eventually, a day trip to a winery in Florence.
          Plus, we flew in and out of London from Boston to spend time with dear
          friends. It was the perfect full-circle moment to our favorite love
          story - one that&apos;s just getting started.
        </p>
      )
    }
  ];

  const onToggleReadMore = (idx) => {
    const el = document.querySelector(`#story_item_${idx}`);
    if (el.classList.contains('collapsed')) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < TIMELINE_CONTENT.length; i++) {
        document.querySelector(`#story_item_${i}`).classList.add('collapsed');
        document.querySelector(`#story_item_${i}`).classList.remove('expanded');
      }
      el.classList.remove('collapsed');
      el.classList.add('expanded');
      el.scrollIntoView();
    } else {
      document.activeElement.blur();
      el.classList.add('collapsed');
      el.classList.remove('expanded');
      el.scrollIntoView({ block: 'center', behavior: 'instant' });
    }
  };

  const getTimelineItems = (content) =>
    content.map((item, idx) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        className='item collapsed'
        id={`story_item_${idx}`}
      >
        <button
          type='button'
          className='story-icon'
          onClick={() => onToggleReadMore(idx)}
        >
          {item.icon}
        </button>
        <div className='story-content'>
          <div className='image-wrap'>
            <div className='single-image'>
              <div>
                <img src={item.image} alt='' />
              </div>
            </div>
          </div>

          <h2>{item.date}</h2>
          <span className='date'>{item.title}</span>
          <div className='story-text'>{item.storyContent}</div>
        </div>
      </div>
    ));

  return (
    <div id='story-section' className='page-section'>
      <SectionTitle section='Our Story' />
      <Container>
        <Row xs={1}>
          <Col
            style={{
              marginTop: '-20px',
              marginBottom: '10px',
              fontSize: '1.2rem'
            }}
            className='text-center'
          >
            <p
              className='fst-italic mt-3 mt-md-4'
              style={{
                fontSize: '1rem'
              }}
            >
              Click each icon to learn more.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className='story clearfix'>
              <div className='story-top' />
              <div className='content-wrapper'>
                {getTimelineItems(TIMELINE_CONTENT)}
              </div>
              <div className='story-bottom' />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Story;
