import React from 'react';

import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import SectionTitle from 'Components/utility/section-title';
import Hotels from './tabs/hotels';
import Parking from './tabs/parking';
import Recommendations from './tabs/recommendations';

const TravelPage = () => (
  <div id='crew-section' className='page-section'>
    <SectionTitle section='Travel &amp; Stay' />
    <Container>
      <div className='person-area-menu'>
        <div className='person-btn'>
          <Tabs id='crew-tabs' defaultActiveKey='parking' className='mb-3'>
            <Tab eventKey='parking' title='Parking &amp; Shuttle'>
              <Parking />
            </Tab>
            <Tab eventKey='hotels' title='Hotels'>
              <Hotels />
            </Tab>
            <Tab eventKey='boston-recs' title='Boston Recommendations'>
              <Recommendations />
            </Tab>
          </Tabs>
        </div>
      </div>
    </Container>
  </div>
);

export default TravelPage;
