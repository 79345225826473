/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useAuth } from 'Hooks';
import useAdminApi from 'Hooks/useAdminApi';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { Navigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const Admin = () => {
  const { user } = useAuth();

  const [error, setError] = useState();
  const [guests, setGuests] = useState([]);
  const { adminFetchAllGuests } = useAdminApi();
  useEffect(() => {
    const fetchGuests = async () => {
      const resp = await adminFetchAllGuests();
      if (resp) {
        const guestsReal = resp.filter((g) => g.lastName !== 'Marino-Colbert');
        setGuests([...guestsReal]);
      } else {
        // setError('Failed to fetch guests');
      }
      return resp;
    };
    fetchGuests();
  }, []);

  if (!user?.isAdmin) {
    return <Navigate to='/' />;
  }

  const convertTrueFalseEmpty = (val, emptyReplacement = '-') => {
    if (!val) {
      return emptyReplacement;
    }
    return val === 'true' ? 'Yes' : 'No';
  };

  const tbody = guests.map((guest, idx) => (
    <tr key={guest.invitationId + guest.guestId}>
      <td>{idx + 1}</td>
      <td>{guest.invitationId}</td>
      <td>{`${guest.firstName} ${guest.lastName}`}</td>
      <td>{convertTrueFalseEmpty(guest.isAttending, 'awaiting response')}</td>
      <td>{guest.mealSel || '-'}</td>
      <td>{guest.dietaryRestrictions || '-'}</td>
      <td>{guest.specialReq || '-'}</td>
      <td>{guest.attendingWelcomeDrinks || '-'}</td>
      <td>
        {guest.rehearsalDinnerAttendee === 'true'
          ? guest.attendingRehearsal || '-'
          : 'NA'}
      </td>
      <td>{guest.attendingAfterParty || '-'}</td>
      <td>{guest.attendingBrunch || '-'}</td>
      <td>{guest.email || '-'}</td>
      <td>{guest.message || '-'}</td>
    </tr>
  ));

  let yesCount = 0;
  let noCount = 0;
  let awaitingCount = 0;

  const brunchCount = { yes: 0, no: 0, maybe: 0 };
  const afterPartyCount = { yes: 0, no: 0, maybe: 0 };
  const welcomeDrinksCount = { yes: 0, no: 0, maybe: 0 };
  const rehearsalDinnerCount = { yes: 0, no: 0, maybe: 0 };

  const eventCountHandler = (resp, eventCounter) => {
    switch (resp) {
      case 'yes':
        eventCounter.yes += 1;
        break;
      case 'no':
        eventCounter.no += 1;
        break;
      case 'maybe':
        eventCounter.maybe += 1;
        break;
      default:
        break;
    }
  };

  guests.forEach((g) => {
    switch (g.isAttending) {
      case 'true':
        yesCount += 1;
        break;
      case 'false':
        noCount += 1;
        break;
      default:
        awaitingCount += 1;
        break;
    }
    eventCountHandler(g.attendingAfterParty, afterPartyCount);
    eventCountHandler(g.attendingBrunch, brunchCount);
    eventCountHandler(g.attendingWelcomeDrinks, welcomeDrinksCount);
    eventCountHandler(g.attendingRehearsal, rehearsalDinnerCount);
  });

  return (
    <Container>
      <Alert show={!!error} variant='danger'>
        {error}
      </Alert>

      <Row className='mt-4'>
        <Col className='mb-3' xs={8} lg={3}>
          <Card style={{ minHeight: '225px' }}>
            <Card.Header>Response Summary</Card.Header>
            <Card.Body className='align-items-center'>
              <Row className='align-items-center'>
                <Col xs={6} style={{ fontWeight: 'bold' }}>
                  Total
                </Col>
                <Col>{`${yesCount + noCount}/${guests.length}`}</Col>
              </Row>
              <Row className='align-items-center'>
                <Col xs={7} style={{ fontWeight: 'bold' }}>
                  Attending
                </Col>
                <Col>{yesCount}</Col>
              </Row>
              <Row className='align-items-center'>
                <Col xs={7} style={{ fontWeight: 'bold' }}>
                  Declined
                </Col>
                <Col>{noCount}</Col>
              </Row>
              <Row className='align-items-center'>
                <Col xs={7} style={{ fontWeight: 'bold' }}>
                  Awaiting
                </Col>
                <Col>{awaitingCount}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col xs={6} lg={3}>
          <Card style={{ minHeight: '170px' }}>
            <Card.Body>
              <Card.Title className='mb-3' style={{ fontSize: '1.1rem' }}>
                Event Summary
              </Card.Title>
              <Row className='align-items-center'>
                <Col xs={7} style={{ fontWeight: 'bold' }}>
                  Welcome
                </Col>
                <Col>{welcomeDrinksCount}</Col>
              </Row>
              <Row className='align-items-center'>
                <Col xs={7} style={{ fontWeight: 'bold' }}>
                  Party
                </Col>
                <Col>{afterPartyCount}</Col>
              </Row>
              <Row className='align-items-center'>
                <Col xs={7} style={{ fontWeight: 'bold' }}>
                  Rehearsal
                </Col>
                <Col>{rehearsalDinnerCount}</Col>
              </Row>
              <Row className='align-items-center'>
                <Col xs={7} style={{ fontWeight: 'bold' }}>
                  brunch
                </Col>
                <Col>{brunchCount}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col> */}
        <Col className='mb-3' xs={8} lg={5}>
          <Card style={{ minHeight: '225px' }}>
            <Card.Header>Event Summary</Card.Header>
            <Card.Body className='align-items-center'>
              <Table variant='' borderless responsive='md'>
                <thead>
                  <tr>
                    <th />
                    <th>Yes</th>
                    <th>No</th>
                    <th>Maybe</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='py-0 my-0'>
                      <span style={{ fontWeight: 'bold' }}>Welcome</span>
                    </td>
                    <td className='py-0 my-0'>{welcomeDrinksCount.yes}</td>
                    <td className='py-0 my-0'>{welcomeDrinksCount.no}</td>
                    <td className='py-0 my-0'>{welcomeDrinksCount.maybe}</td>
                  </tr>
                  <tr>
                    <td className='py-0 my-0'>
                      <span style={{ fontWeight: 'bold' }}>Party</span>
                    </td>
                    <td className='py-0 my-0'>{afterPartyCount.yes}</td>
                    <td className='py-0 my-0'>{afterPartyCount.no}</td>
                    <td className='py-0 my-0'>{afterPartyCount.maybe}</td>
                  </tr>
                  <tr className='py-0 my-0'>
                    <td className='py-0 my-0'>
                      <span style={{ fontWeight: 'bold' }}>Brunch</span>
                    </td>
                    <td className='py-0 my-0'> {brunchCount.yes}</td>
                    <td className='py-0 my-0'>{brunchCount.no}</td>
                    <td className='py-0 my-0'>{brunchCount.maybe}</td>
                  </tr>
                  <tr>
                    <td className='py-0 my-0'>
                      <span style={{ fontWeight: 'bold' }}>Rehearsal</span>
                    </td>
                    <td className='py-0 my-0'>{rehearsalDinnerCount.yes}</td>
                    <td className='py-0 my-0'>{rehearsalDinnerCount.no}</td>
                    <td className='py-0 my-0'>{rehearsalDinnerCount.maybe}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className='justify-content-center mt-3'>
        <Col>
          <Table variant='' responsive='md'>
            <thead>
              <tr>
                <th>#</th>
                <th>Invitation Code</th>
                <th>Guest Name</th>
                <th>Is Attending</th>
                <th>Meal</th>
                <th>Dietary Needs</th>
                <th>Other Needs</th>
                <th>Drinks</th>
                <th>Rehearsal</th>
                <th>After Party</th>
                <th>Brunch</th>
                <th>Email</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>{tbody}</tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Admin;
