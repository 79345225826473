import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare, faUser } from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';

import GuestLoginModal from 'Components/login/guest-login-modal';

import '../Styles/menu.scss';
import { useAuth } from 'Hooks';

const Menu = () => {
  const [expand, setExpand] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { user, signOut } = useAuth();

  const closeLoginModal = (showRsvp) => {
    setShowLoginModal(false);
    if (showRsvp) {
      navigate('/rsvp');
    }
  };

  useEffect(() => {
    if (state?.showLoginModal) {
      setShowLoginModal(true);
    }
  }, [state]);

  const handleSignOut = () => {
    signOut();
    navigate('/guest/gallery');
  };

  return (
    <div id='menu' className='menu-wrapper'>
      <GuestLoginModal show={showLoginModal} onHide={closeLoginModal} />

      <Navbar
        bg='black'
        expand='md'
        expanded={expand}
        variant='dark'
        className='fixed-top menu-bar'
        style={{ fontWeight: 'bold' }}
        onToggle={(eventKey) => {
          setExpand(eventKey);
        }}
        onSelect={() => {
          setExpand(false);
        }}
      >
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className='m-auto w-100 nav-fill'>
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink}>Home</Dropdown.Toggle>
              <Dropdown.Menu className='text-center' variant='dark'>
                <Dropdown.Item
                  as={Link}
                  eventKey='story'
                  to={{
                    pathname: '/',
                    hash: 'story-section'
                  }}
                  state={{ fromMenu: true }}
                  replace={pathname === '/'}
                >
                  Our Story
                </Dropdown.Item>

                <Dropdown.Item
                  as={Link}
                  eventKey='events'
                  to={{
                    pathname: '/',
                    hash: 'events-section'
                  }}
                  state={{ fromMenu: true }}
                  replace={pathname === '/'}
                >
                  Events
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  eventKey='crew'
                  to={{
                    pathname: '/',
                    hash: 'crew-section'
                  }}
                  state={{ fromMenu: true }}
                  replace={pathname === '/'}
                >
                  Crew
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <NavItem>
              <Nav.Link as={Link} eventKey='travel' to='/travel'>
                Travel &amp; Stay
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={Link} eventKey='faq' to='/faq'>
                FAQ
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={Link} eventKey='rsvp' to='/rsvp'>
                RSVP
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={Link} eventKey='gallery' to='guest/gallery'>
                Gallery
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link
                href='https://registry.theknot.com/victoria-marino-ben-colbert-november-2023-ma/61230684'
                target='_blank'
              >
                Registry{' '}
                <FontAwesomeIcon
                  style={{ fontSize: '1rem' }}
                  icon={faUpRightFromSquare}
                />
              </Nav.Link>
            </NavItem>
            {user ? (
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={NavLink}>
                  <FontAwesomeIcon icon={faUser} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='text-center' variant='dark'>
                  <Dropdown.Item onClick={handleSignOut}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Menu;
