import React from 'react';

import Spinner from 'react-bootstrap/Spinner';

// eslint-disable-next-line no-confusing-arrow
const GenericSpinner = ({ isVisible }) =>
  isVisible ? (
    <div className='text-center'>
      <Spinner animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  ) : null;

export default GenericSpinner;
