/* eslint-disable no-unused-vars */
import React from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'Hooks';

import { API, graphqlOperation } from 'aws-amplify';
import { createMediaShareRequest } from 'graphql/mutations';

const TestPage = () => {
  const { user } = useAuth();

  if (!user?.isAdmin) {
    return <Navigate to='/' />;
  }

  const makeThing = async () => {
    console.log('Creating');
    try {
      const data = {
        mediaItemId: 'bae4f207-48c5-4658-9fb2-8e8172097c63'
      };
      const resp = await API.graphql(
        graphqlOperation(createMediaShareRequest, {
          input: data
        })
      );
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <Row>
        <Col>
          <Button onClick={makeThing}>Share!</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default TestPage;
