import React, { useState, useEffect, useRef, useMemo } from 'react';

import { saveAs } from 'file-saver';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import throttle from 'lodash.throttle';
import Alert from 'react-bootstrap/Alert';
import Carousel from 'react-bootstrap/Carousel';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import FormCheck from 'react-bootstrap/FormCheck';
import {
  faXmark,
  faCheck,
  faTrash,
  faDownload,
  faPlay,
  faFilm
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SectionTitle from 'Components/utility/section-title';
import useGallery from 'Hooks/useGallery';
import { ReactComponent as Logo } from '../../../logo2.svg';
import ImageUploader from './components/image-uploader';
import AdminGalleryControls from './components/admin-gallery-controls';

import 'Styles/gallery.scss';

const SLIDE_SHOW_OFFSET = 25;
const SLIDE_SHOW_BUFFER_SIZE = 2 * SLIDE_SHOW_OFFSET + 1;
const SLIDE_SHOW_OFFSCREEN_RENDER = 2;

// TODO:
// 1/ Improve Page Refresh after upload
// 2/ Upload & Retrieve error handling
// 3/ Fallback images before load
// 4/ Make delete actually delete
// 5/ Fix downloads
// 6/ !!Pagination is broken beyond a few thousand images

const EventGallery = () => {
  const [showCarousel, setShowCarousel] = useState();
  const { galleryId } = useParams();
  const [init, setInit] = useState(false);
  const [mediaBuffer, setMediaBuffer] = useState([]);
  const [slideShow, setSlideShow] = useState(false);
  const [fetchingThumbnails, setFetchingThumbnails] = useState(false);
  const [carouselInterval, setCarouselInterval] = useState(null);
  const [carouselDisplayMode, setCarouselDisplayMode] = useState('splash');
  const {
    activeGallery,
    activeGalleryMediaItems,
    paginate,
    loadingGalleryData,
    loadActiveGallery,
    deleteGalleryItem,
    loadingGalleries,
    shouldPaginate
  } = useGallery();
  const [pageError, setPageError] = useState();
  const [mediaSelectActive, setMediaSelectActive] = useState(false);
  const [pendingDownload, setPendingDownload] = useState(false);
  const [multiSelectList, setMultiSelectList] = useState([]);
  const { isAdmin } = useOutletContext();
  const playerMapRef = useRef(new Array(SLIDE_SHOW_BUFFER_SIZE));
  const [selCarouselPhoto, setSelCarouselPhoto] = useState(0);
  const [carouselBuffer, setCarouselBuffer] = useState([]);
  const activeMediaRef = useRef([]);

  const callbackUtilityRef = useRef({
    mediaBufferSize: mediaBuffer.length,
    activeGalleryMediaItemsSize: activeGalleryMediaItems.length,
    loadingGalleryData
  });

  activeMediaRef.current = activeGalleryMediaItems;

  const handleMultiSelect = (idx) => {
    const match = multiSelectList.findIndex((l) => l === idx);
    if (match > -1) {
      const before = multiSelectList.slice(0, match);
      const after = multiSelectList.slice(match + 1, multiSelectList.length);
      setMultiSelectList([...before, ...after]);
      document
        .getElementById(`gallery-thumbnail-${idx}`)
        .classList.remove('selected');
    } else {
      setMultiSelectList([...multiSelectList, idx]);
      document
        .getElementById(`gallery-thumbnail-${idx}`)
        .classList.add('selected');
    }
    document.getElementById(`gallery-thumbnail-check-${idx}`).click();

    document
      .getElementById(`gallery-thumbnail-${idx}`)
      .classList.add('selected');
  };

  const loadOffScreenContent = (index) => {
    for (
      let t = index - SLIDE_SHOW_OFFSCREEN_RENDER;
      t <= index + SLIDE_SHOW_OFFSCREEN_RENDER;
      t++
    ) {
      let tIdx = t;
      if (tIdx < 0) {
        tIdx = SLIDE_SHOW_BUFFER_SIZE + tIdx;
      } else if (tIdx >= SLIDE_SHOW_BUFFER_SIZE) {
        tIdx -= SLIDE_SHOW_BUFFER_SIZE;
      }
      const el = document.getElementById(`slideshow-media-item-${tIdx}`);
      if (el) {
        const dataSrc = el.getAttribute('data-src');
        if (el.src !== dataSrc) {
          el.src = dataSrc;
        }
      }
    }
  };

  const setCarouselElementsFromActiveMediaItems = (center) => {
    if (activeGalleryMediaItems.length <= SLIDE_SHOW_BUFFER_SIZE) {
      const arr = activeGalleryMediaItems.map((item, idx) => ({
        url: item.url,
        mediaType: item.mediaType,
        id: item.id,
        baseIndex: idx
      }));
      setCarouselBuffer([...arr]);
      return center;
    }

    const n = activeGalleryMediaItems.length;
    const subarray = [];
    for (
      let i = center - SLIDE_SHOW_OFFSET;
      i <= center + SLIDE_SHOW_OFFSET;
      i++
    ) {
      // Handle circular indexing
      let index = i;
      if (index < 0) {
        index = n + index;
      } else if (index >= n) {
        index -= n;
      }

      const mediaItem = activeGalleryMediaItems[index];

      subarray.push({
        url: mediaItem.url,
        mediaType: mediaItem.mediaType,
        id: mediaItem.id,
        baseIndex: index
      });
    }
    setCarouselBuffer([...subarray]);
    return SLIDE_SHOW_OFFSET;
  };

  const openCarousel = (idx, id) => {
    // Disable all scrolling when the carousel is loaded
    document.documentElement.classList.add('scroll-off');
    let target = idx;
    if (carouselDisplayMode === 'gallery') {
      const currIndex = carouselBuffer.findIndex((el) => el.id === id);
      if (currIndex < 0) {
        target = setCarouselElementsFromActiveMediaItems(target);
      } else {
        target = currIndex;
      }
    } else {
      target = 0;
    }

    setSelCarouselPhoto(target);
    setShowCarousel(true);
  };

  const handleThumbnailClick = (idx) => {
    if (mediaSelectActive) {
      handleMultiSelect(idx);
    } else {
      const id = activeGalleryMediaItems[idx]?.id || -1;
      openCarousel(idx, id);
    }
  };

  const closeCarousel = () => {
    setShowCarousel(false);

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    // Disable all scrolling when the carousel is loaded
    document.documentElement.classList.remove('scroll-off');
  };

  useEffect(() => {
    if (loadingGalleries === undefined || loadingGalleries === true) {
      return;
    }
    if (!init) {
      loadActiveGallery(galleryId);
      setInit(true);
    }
  }, [loadingGalleries]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key } = event;
      if (key === 'Escape') {
        closeCarousel();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    loadOffScreenContent(selCarouselPhoto);
  }, [selCarouselPhoto]);

  useEffect(() => {
    if (activeGallery?.id === galleryId) {
      if (
        activeGalleryMediaItems.length > 0 &&
        carouselBuffer.length === 0 &&
        carouselDisplayMode === 'splash'
      ) {
        setCarouselDisplayMode('gallery');
        setCarouselElementsFromActiveMediaItems(0);
        loadOffScreenContent(0);
      }
      if (
        !fetchingThumbnails &&
        mediaBuffer.length > 0 &&
        activeGalleryMediaItems.length > 0
      ) {
        if (mediaBuffer[0].id !== activeGalleryMediaItems[0].idx) {
          let run = 0;
          while (
            run < activeGalleryMediaItems.length &&
            activeGalleryMediaItems[run].id !== mediaBuffer[0].id
          ) {
            run += 1;
          }
          if (run > 0) {
            setMediaBuffer((curr) => [
              ...activeGalleryMediaItems.slice(0, run),
              ...curr
            ]);
          }
        }
      } else if (fetchingThumbnails) {
        setMediaBuffer((curr) => [
          ...curr,
          ...activeGalleryMediaItems.slice(curr.length, curr.length + 40)
        ]);
        setFetchingThumbnails(false);
      } else {
        setMediaBuffer((curr) => [
          ...activeGalleryMediaItems.slice(curr.length, curr.length + 40)
        ]);
      }
    }
  }, [activeGalleryMediaItems, activeGallery, loadingGalleries]);

  callbackUtilityRef.current = {
    init,
    mediaBufferSize: mediaBuffer.length,
    activeGalleryMediaItemsSize: activeGalleryMediaItems.length,
    loadingGalleryData
  };

  useEffect(() => {
    const initialized = () =>
      callbackUtilityRef.current.init &&
      !callbackUtilityRef.current.loadingGalleryData;

    const shouldClientPagination = () =>
      callbackUtilityRef.current.mediaBufferSize <
      callbackUtilityRef.current.activeGalleryMediaItemsSize;

    const tryPaginate = throttle(async () => {
      if (initialized()) {
        if (shouldClientPagination()) {
          setFetchingThumbnails(true);
          setMediaBuffer((curr) => [
            ...curr,
            ...activeMediaRef.current.slice(curr.length, curr.length + 20)
          ]);
        } else if (shouldPaginate()) {
          setFetchingThumbnails(true);
          paginate();
        } else {
          setFetchingThumbnails(false);
        }
      }
    }, 1000);
    const onScroll = () => {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 80
      ) {
        tryPaginate(activeGalleryMediaItems);
      }
    };
    const onFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setCarouselInterval(null);
        setSlideShow(false);
      }
    };

    document.addEventListener('fullscreenchange', onFullscreenChange);
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [loadingGalleryData]);

  const renderThumbnails = () =>
    mediaBuffer.map((item, idx) => (
      <Col
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        className='image-col px-0 '
      >
        <div className='align-self-right gallery-thumbnail'>
          <FormCheck
            id={`gallery-thumbnail-check-${idx}`}
            className='thumbnail-icon check'
          />

          <div className='img-wrap'>
            <Image
              src={item.thumbnailUrl}
              id={`gallery-thumbnail-${idx}`}
              alt=''
              onClick={() => handleThumbnailClick(idx, item.id)}
            />
            {item.mediaType === 'video' ? (
              <FontAwesomeIcon className='thumbnail-media-icon' icon={faFilm} />
            ) : null}
          </div>
        </div>
      </Col>
    ));

  const handleSelectFabClick = () => {
    if (mediaSelectActive) {
      multiSelectList.forEach((id) => {
        document.getElementById(`gallery-thumbnail-check-${id}`).click();
      });
      setMultiSelectList([]);
    }
    setMediaSelectActive(!mediaSelectActive);
  };

  const gallerySectionClasses = ['justify-content-center', 'gallery-section'];
  const thumbnailSelectMenuClasses = ['fab-menu'];
  if (mediaSelectActive) {
    gallerySectionClasses.push('selectable');
    thumbnailSelectMenuClasses.push('active');
  }

  const handleDelete = async () => {
    try {
      multiSelectList.forEach(async (idx) => {
        const { _version, id } = mediaBuffer[idx];
        await deleteGalleryItem(id, _version);
      });
    } catch (e) {
      console.error(e);
    } finally {
      handleSelectFabClick();
      setPendingDownload(false);
    }
  };

  // TODO - THIS IS BROKEN. FIX IT
  const downloadImg = async (url, name) => {
    const resp = await fetch(url);
    const blob = await resp.blob();
    saveAs(blob, name);
    return name;
  };
  const handleDownload = async () => {
    setPendingDownload(true);
    try {
      multiSelectList.map(async (id) => {
        const { url, name } = mediaBuffer[id];
        await downloadImg(url, name);
      });
    } catch (e) {
      console.error(e);
    } finally {
      handleSelectFabClick();
      setPendingDownload(false);
    }
  };

  const galleryName = activeGallery?.id === galleryId ? activeGallery.name : '';

  const startSlideShow = () => {
    if (!showCarousel) {
      // const id = activeGalleryMediaItems[0]?.id || -1;
      openCarousel(0);
    }
    setCarouselInterval(7000);
    setSlideShow(true);
    document.body.requestFullscreen();
  };

  const getSlides = useMemo(() => {
    if (carouselDisplayMode === 'splash') {
      return Array(1)
        .fill(undefined)
        .map(() => (
          <Carousel.Item key='key' id='slideshow-empty-slide'>
            <div className='media-wrap'>
              <Logo
                style={{ stroke: 'white', strokeWidth: '3px', height: '400px' }}
              />
            </div>
          </Carousel.Item>
        ));
    }

    return carouselBuffer.map((item, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Carousel.Item key={idx} id={`slideshow-item-${idx}`}>
        <div className='media-wrap'>
          {item.mediaType === 'image' ? (
            <img
              className={item.mediaType !== 'image' ? 'hide' : ''}
              id={`slideshow-media-item-${idx}`}
              alt=''
              src={idx === selCarouselPhoto ? item.url : ''}
              data-src={item.url}
              style={{
                objectFit: 'contain',
                height: '100%',
                width: '100%',
                marginTop: 'auto',
                alignSelf: 'flex-end'
              }}
            />
          ) : (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              className={item.mediaType !== 'video' ? 'hide' : ''}
              id={`slideshow-media-item-${idx}`}
              src={idx === selCarouselPhoto ? item.url : ''}
              data-src={item.url}
              loop={false}
              playsInline
              controls
              height='100%'
              width='100%'
              ref={(player) => {
                playerMapRef.current[idx] = player;
              }}
            />
          )}
        </div>
      </Carousel.Item>
    ));
  }, [carouselBuffer, carouselDisplayMode, loadingGalleryData]);

  const isSlideShowGallery = [
    '59e8ed3a-8d51-402a-b93b-0053c6fc42b0',
    '98b2b570-3b3e-4584-bd2c-2b7edd2dcd38',
    'f08aab67-8a41-42ea-9c68-ee641ad8bbf9'
  ].includes(galleryId);

  const isVideoGuestBook = galleryId === 'd3353e09-c4e5-4e7c-a2ce-a0e1613b77ec';
  const isPhotoBoothGallery =
    galleryId === '98b2b570-3b3e-4584-bd2c-2b7edd2dcd38';

  return (
    <Container>
      <Modal
        id='carousel-modal'
        className='preview-modal'
        fullscreen
        show={showCarousel}
        animation={false}
        onExit={closeCarousel}
      >
        <Modal.Body
          style={{
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className='media-carousel-controls mb-2'>
            {isSlideShowGallery && !slideShow ? (
              <Button
                variant='outline-light'
                className='slideshow-btn'
                type='button'
                onClick={startSlideShow}
              >
                Slide Show{' '}
                <FontAwesomeIcon icon={faPlay} className='slideshow-btn-icon' />
              </Button>
            ) : null}
            <button className='exit-btn ' type='button' onClick={closeCarousel}>
              <FontAwesomeIcon
                style={{
                  color: 'white',
                  padding: '10px'
                }}
                icon={faXmark}
                size='xl'
              />
            </button>
          </div>

          <Carousel
            className='media-carousel'
            indicators={false}
            slide={false}
            pause={false}
            activeIndex={selCarouselPhoto}
            interval={carouselInterval}
            controls={!slideShow && activeGalleryMediaItems.length !== 0}
            touch={!slideShow}
            onSelect={(idx) => {
              let target = idx;
              if (carouselDisplayMode === 'gallery') {
                if (activeGalleryMediaItems.length > carouselBuffer.length) {
                  if (idx === 0 || idx === carouselBuffer.length - 1) {
                    setCarouselElementsFromActiveMediaItems(
                      carouselBuffer[idx].baseIndex
                    );
                    target =
                      activeGalleryMediaItems.length > SLIDE_SHOW_BUFFER_SIZE
                        ? SLIDE_SHOW_OFFSET
                        : idx + 1;
                  }
                }
              }
              if (target !== selCarouselPhoto) {
                const player = playerMapRef.current[selCarouselPhoto]; // ?.getInternalPlayer();
                if (player) {
                  player.pause();
                }
                setSelCarouselPhoto(target);
              }
            }}
          >
            {getSlides}
          </Carousel>
        </Modal.Body>
      </Modal>
      <Breadcrumb className='pt-1'>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/guest/gallery' }}>
          Galleries
        </Breadcrumb.Item>
        <Breadcrumb.Item>{galleryName || ''}</Breadcrumb.Item>
      </Breadcrumb>

      <SectionTitle
        section={galleryName || ''}
        topPadding={3}
        bottomPadding={4}
      />
      <Row>
        <Col>
          <Alert show={!!pageError} variant='danger'>
            {pageError}
          </Alert>
        </Col>
      </Row>
      {isVideoGuestBook ? (
        <Row className='justify-content-center'>
          <Col md={10} xs={10}>
            <Alert variant='dark' className='text-center'>
              Your guest book submission is really important to us.
              <br /> If you experience any problems, please email your video to{' '}
              <a href='mailto: marino-colbert@gmail.com'>
                marino-colbert@gmail.com
              </a>
              .
            </Alert>
          </Col>
        </Row>
      ) : null}
      <Row className='justify-content-center'>
        <Col md={10} xs={10}>
          <AdminGalleryControls
            gallery={activeGallery}
            modes={['update']}
            onCreateUpdate={() => {}}
          />
        </Col>
      </Row>

      {activeGallery && activeGallery.id === galleryId ? (
        <>
          {!isPhotoBoothGallery ? (
            <Row className='media-ctrl-row mb-3'>
              <Col xs={12}>
                <ImageUploader
                  galleryId={activeGallery?.id}
                  galleryName={galleryName || ''}
                  allowMultiple={!isVideoGuestBook}
                  mediaType={isVideoGuestBook ? 'video' : 'all'}
                  onError={(err) => {
                    if (err && err.length > 0) {
                      setPageError(
                        'Error uploading images. Try uploading fewer files and tell Ben to fix it.'
                      );
                    } else {
                      setPageError();
                    }
                  }}
                />
              </Col>
            </Row>
          ) : (
            <Row className='justify-content-center text-center my-4'>
              <Col xs={12}>
                <Button
                  variant='outline-dark'
                  className='slideshow-btnnnn'
                  type='button'
                  onClick={startSlideShow}
                >
                  Slide Show{' '}
                  <FontAwesomeIcon
                    icon={faPlay}
                    className='slideshow-btn-icon'
                  />
                </Button>
              </Col>
            </Row>
          )}
          <Row lg={4} md={3} xs={3} className={gallerySectionClasses.join(' ')}>
            {renderThumbnails()}
          </Row>
        </>
      ) : null}

      <Row className='justify-content-center text-center my-4'>
        <Col>
          {loadingGalleryData || fetchingThumbnails ? (
            <div loading='' className='d-flex justify-content-center'>
              <Spinner animation='grow' size='sm' className='mx-2' />
              <Spinner animation='grow' size='sm' className='mx-2' />
              <Spinner animation='grow' size='sm' className='mx-2' />
            </div>
          ) : null}
        </Col>
      </Row>
      {isAdmin ? (
        <Stack className={thumbnailSelectMenuClasses.join(' ')} gap={3}>
          <Button
            className='item'
            disabled={multiSelectList.length === 0}
            onClick={handleDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
          <Button
            className='item'
            disabled={multiSelectList.length === 0}
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            variant='primary'
            className='fab-menu-button'
            onClick={handleSelectFabClick}
          >
            {pendingDownload ? (
              <Spinner size='sm' />
            ) : (
              <FontAwesomeIcon icon={mediaSelectActive ? faXmark : faCheck} />
            )}
          </Button>
        </Stack>
      ) : null}
    </Container>
  );
};

export default EventGallery;
