/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGallery = /* GraphQL */ `
  query GetGallery(
    $id: ID!
    $limit: Int
    $nextToken: String
    $mediaFilter: ModelMediaItemFilterInput
  ) {
    getGallery(id: $id) {
      id
      name
      galleryUsers
      media(
        limit: $limit
        nextToken: $nextToken
        sortDirection: DESC
        filter: $mediaFilter
      ) {
        items {
          id
          galleryId
          name
          createdAt
          contentType
          sizeBytes
          mediaType
          status
          src
          fileExt
          thumbnailSrc
          pendingUploadTimeout
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      active
      location
      eventDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGalleries = /* GraphQL */ `
  query ListGalleries(
    $filter: ModelGalleryFilterInput
    $limit: Int
    $nextToken: String
    $mediaLimit: Int
    $mediaFilter: ModelMediaItemFilterInput
  ) {
    listGalleries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        galleryUsers
        media(limit: $mediaLimit, sortDirection: DESC, filter: $mediaFilter) {
          items {
            id
            galleryId
            name
            createdAt
            contentType
            mediaType
            thumbnailSrc
            pendingUploadTimeout
            updatedAt
            _version
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        active
        location
        eventDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGalleries = /* GraphQL */ `
  query SyncGalleries(
    $filter: ModelGalleryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGalleries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        galleryUsers
        media {
          nextToken
          startedAt
          __typename
        }
        active
        location
        eventDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMediaItem = /* GraphQL */ `
  query GetMediaItem($id: ID!) {
    getMediaItem(id: $id) {
      id
      galleryId
      name
      createdAt
      contentType
      sizeBytes
      mediaType
      status
      src
      fileExt
      thumbnailSrc
      uploadUrl {
        url
        fields
        __typename
      }
      pendingUploadTimeout
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listMediaItems = /* GraphQL */ `
  query ListMediaItems(
    $filter: ModelMediaItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMediaItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        galleryId
        name
        createdAt
        contentType
        sizeBytes
        mediaType
        status
        src
        fileExt
        thumbnailSrc
        uploadUrl {
          url
          fields
          __typename
        }
        pendingUploadTimeout
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMediaItems = /* GraphQL */ `
  query SyncMediaItems(
    $filter: ModelMediaItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMediaItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        galleryId
        name
        createdAt
        contentType
        sizeBytes
        mediaType
        status
        src
        fileExt
        thumbnailSrc
        uploadUrl {
          url
          fields
          __typename
        }
        pendingUploadTimeout
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mediaItemsByGalleryIdAndCreatedAt = /* GraphQL */ `
  query MediaItemsByGalleryIdAndCreatedAt(
    $galleryId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMediaItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mediaItemsByGalleryIdAndCreatedAt(
      galleryId: $galleryId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        galleryId
        name
        createdAt
        contentType
        sizeBytes
        mediaType
        status
        src
        fileExt
        thumbnailSrc
        uploadUrl {
          url
          fields
          __typename
        }
        pendingUploadTimeout
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
