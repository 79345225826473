import React from 'react';

import Container from 'react-bootstrap/Container';

const Recommendations = () => (
  <div id='hotel-section' className='page-section pb-5'>
    <Container className='text-center'>
      <h2 className='pb-4' style={{ fontFamily: 'Brittany' }}>
        Coming Soon
      </h2>
      <p>Check back soon for a small list of our favorite places in Boston</p>
    </Container>
  </div>
);

export default Recommendations;
