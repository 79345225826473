/* eslint-disable indent */

import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SectionTitle from 'Components/utility/section-title';
import { AppContext } from 'Context/app-context';

import emmanuelImg from 'Images/events/emmanuel.jpg';
import towerImg from 'Images/events/tower.jpg';
import mjoImg from 'Images/events/mjo.jpg';

import 'Styles/events.scss';
import EventDate from './components/event-date';

const WELCOME_DRINKS_DATE = new Date('November 08, 2023 19:00:00');

const WEDDING_DATE = new Date('November 10, 2023 19:00:00');
const BRUNCH_DATE = new Date('November 11, 2023 19:00:00');

const Events = () => {
  const { isIos } = useContext(AppContext);

  const links = isIos
    ? {
        aeronautLink:
          'https://maps.apple.com/place?q=Aeronaut%20Brewing%20Company&ll=42.381981%2C-71.106293&auid=5109636907806534425&lsp=9902&address=14%20Tyler%20St%2C%20Somerville%2C%20MA%20%2002143%2C%20United%20States',
        emmanuelLink:
          'https://maps.apple.com/?address=15%20Newbury%20St,%20Boston,%20MA%20%2002116,%20United%20States&auid=8418696190259904036&ll=42.352585,-71.072399&lsp=9902&q=Emmanuel%20Church&_ext=CjIKBQgEEOIBCgQIBRADCgQIBhB9CgQIChAACgQIUhAHCgQIVRAQCgQIWRAGCgUIpAEQARImKYncHVSOLEVAMSoeksMFxVHAOQeyQ7C0LUVAQYSDQps+xFHAUAQ%3D&t=m',
        towerLink:
          'https://maps.apple.com/?address=101%20Arlington%20St,%20Boston,%20MA%2002116,%20United%20States&auid=11167791490152405255&ll=42.349604,-71.070072&lsp=9902&q=The%20Tower&_ext=CjMKBQgEEOIBCgQIBRADCgUIBhCfAwoECAoQAAoECFIQAwoECFUQEAoECFkQAwoFCKQBEAESJimX3CijLCxFQDGTXDei38RRwDkVsk7/Ui1FQEEv0lJ8GMRRwFAD&t=m',
        masLink:
          'https://maps.apple.com/?address=207%20Endicott%20St,%20Boston,%20MA%20%2002113,%20United%20States&auid=8212301568719892766&ll=42.366479,-71.057854&lsp=9902&q=Massimino%27s&t=m',
        mjoLink:
          'https://maps.apple.com/?address=27%20Columbus%20Ave,%20Boston,%20MA%2002116,%20United%20States&auid=3835445288866954177&ll=42.351198,-71.069006&lsp=9902&q=MJ%20O%E2%80%99Connor%E2%80%99s&t=m',
        pressedLink:
          'https://maps.apple.com/?address=800%20Boylston%20St,%20Boston,%20MA%2002199,%20United%20States&auid=18181701298156941733&ll=42.347469,-71.081071&lsp=9902&q=Prudential%20Center&t=m'
      }
    : {
        aeronautLink:
          'https://www.google.com/maps/place/Aeronaut+Brewing+Company/@42.3819782,-71.1062548,15z/data=!4m2!3m1!1s0x0:0xcd08ad34fe7dca73?sa=X&ved=2ahUKEwiYncXtr4qBAxX9KlkFHW6GC6AQ_BJ6BAgbEAA&ved=2ahUKEwiYncXtr4qBAxX9KlkFHW6GC6AQ_BJ6BAhDEAg',
        emmanuelLink:
          'https://www.google.com/maps/place/Emmanuel+Episcopal+Church+Of+Boston/@42.3524912,-71.0768766,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37a75208e1e01:0x60416b0a6a3b5cdb!8m2!3d42.3524913!4d-71.0723866!16zL20vMGZ5cnQ4',
        towerLink:
          'https://www.google.com/maps/place/The+Tower:+A+Longwood+Venue/@42.3496396,-71.0722845,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37bc53ce50e5f:0x62a5fc93700e375b!8m2!3d42.3496357!4d-71.0700958!16s%2Fg%2F11fpbjfzjx',
        mjoLink:
          "https://www.google.com/maps/place/M.J.+O'Connor's/@42.3511475,-71.0738206,17z/data=!3m2!4b1!5s0x89e37a7324dd4dad:0x145a5eadf61513f5!4m6!3m5!1s0x89e37a7439eb71c7:0x54ba34b01e13e25f!8m2!3d42.3511436!4d-71.0689497!16s%2Fg%2F1tkmnknv",
        masLink:
          "https://www.google.com/maps/place/Massimino's/@42.3665351,-71.0578856,15z/data=!4m6!3m5!1s0x89e3708c281c9475:0x57c7f392812af061!8m2!3d42.3665351!4d-71.0578856!16s%2Fg%2F1tfcjs65",
        pressedLink:
          'https://www.google.com/maps/place/Pressed+Cafe/@42.347943,-71.0986552,15z/data=!3m1!5s0x89e37a0dd77182cf:0x44fc2652a84c6f4c!4m10!1m2!2m1!1spressed!3m6!1s0x89e37b9359563251:0x21554eb0eae9cb23!8m2!3d42.3462293!4d-71.0812472!15sCgdwcmVzc2VkWgkiB3ByZXNzZWSSAQpyZXN0YXVyYW50mgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVVJYZUZCVVZFdEJFQUXgAQA!16s%2Fg%2F11r996vc67'
      };

  return (
    <div id='events-section' className='page-section'>
      <SectionTitle section='Events' />

      <Container>
        <div className='service-area-menu'>
          <div className='Ceremony-wrap'>
            <Row className='pb-3'>
              <Col sm={12} md={4} lg={{ span: 3 }}>
                <EventDate date={WELCOME_DRINKS_DATE} title='welcome drinks' />
              </Col>
              <Col className='event-info-wrap' sm={12} md={8} lg={9}>
                <Row className='pb-2'>
                  <Col sm={12} lg={8}>
                    <Row>
                      <Col className='bold' xs={4} sm={3} md={3} lg={3} xl={2}>
                        Location
                      </Col>
                      <Col>
                        <a
                          className='gold-link'
                          href={links.aeronautLink}
                          target='_blanker'
                        >
                          Aeronaut Brewing Co., 14 Tyler St. Somerville, MA
                          02143
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='bold' xs={4} sm={3} md={3} lg={3} xl={2}>
                        Time
                      </Col>
                      <Col>6:30 PM - 8:30 PM</Col>
                    </Row>
                    <Row className='pt-3'>
                      <Col sm={12} md={12} lg={12}>
                        <p>
                          Family and friends who live locally or are in town
                          early are invited to join the bride and groom at
                          Aeronaut Brewery, Somerville. Dinner will be provided.
                          Cash bar. Please note that the brewery will be open to
                          the public. If there is a line at the door, head
                          directly to the entrance podium to state you are with
                          the Marino-Colbert party.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='py-4'>
              <Col sm={12} md={4} lg={{ span: 3 }}>
                <EventDate date={WEDDING_DATE} title='wedding day!' />
              </Col>
              <Col className='event-info-wrap' md={8} lg={9}>
                <Row className='pb-2'>
                  <Col sm={12} md={8} lg={8}>
                    <h3 className='event-title'>Ceremony</h3>
                    <Row>
                      <Col className='bold' xs={4} sm={3} md={3} lg={3} xl={2}>
                        Location
                      </Col>
                      <Col>
                        <a
                          className='gold-link'
                          href={links.emmanuelLink}
                          target='_blanker'
                        >
                          Emmanuel Church, 15 Newbury St. Boston, MA 02116
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='bold' xs={4} sm={3} md={3} lg={3} xl={2}>
                        Time
                      </Col>
                      <Col>
                        3:30 PM -{' '}
                        <span style={{ fontStyle: 'italic' }}>
                          Please arrive by 3:15 PM
                        </span>{' '}
                      </Col>
                    </Row>
                    <Row className='pt-3'>
                      <Col sm={12}>
                        Guests will enter through the Lindsey Chapel. The
                        ceremony will be held inside the Main Sanctuary. For
                        information on parking and the shuttle to Emmanuel
                        Church, please see{' '}
                        <Link className='gold-link' to='/travel'>
                          Travel &amp; Stay
                        </Link>
                        .
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={10}
                    md={10}
                    lg={4}
                    className='d-flex align-items-center'
                  >
                    <div className='ceromony-img'>
                      <img src={emmanuelImg} alt='' />
                    </div>
                  </Col>
                </Row>
                <Row className='py-3'>
                  <Col sm={12} md={8} lg={8}>
                    <h3 className='event-title'>Reception</h3>
                    <Row>
                      <Col className='bold' xs={4} sm={3} md={3} lg={3} xl={2}>
                        Location
                      </Col>
                      <Col>
                        <a
                          className='gold-link'
                          href={links.towerLink}
                          target='_blanker'
                        >
                          The Tower, 101 Arlington St. Boston, MA 02116
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='bold' xs={4} sm={3} md={3} lg={3} xl={2}>
                        Time
                      </Col>
                      <Col>4:30 PM - 9:30 PM</Col>
                    </Row>
                    <Row lg={1} className='pt-3'>
                      <Col>
                        The reception will be held at The Tower. Guests will be
                        greeted in the first floor lounge for cocktail hour,
                        followed by dinner on the second floor. After dinner,
                        guests will be invited back to the first floor for
                        dancing and dessert. See the{' '}
                        <Link style={{ color: '#A0743E' }} to='/faq'>
                          FAQ
                        </Link>{' '}
                        for answers to common questions.
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={10}
                    md={10}
                    lg={4}
                    className='d-flex align-items-center'
                  >
                    <div className='ceromony-img'>
                      <img src={towerImg} alt='' />
                    </div>
                  </Col>
                </Row>
                <Row className='py-3'>
                  <Col lg={8}>
                    <h3 className='event-title'>After Party</h3>
                    <Row>
                      <Col className='bold' xs={4} sm={3} md={3} lg={3} xl={2}>
                        Location
                      </Col>
                      <Col>
                        <a
                          className='gold-link'
                          href={links.mjoLink}
                          target='_blanker'
                        >
                          MJ O&apos;Connor&apos;s, 27 Columbus Ave, Boston, MA
                          02116
                        </a>
                      </Col>

                      <Row>
                        <Col
                          className='bold'
                          xs={4}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={2}
                        >
                          Time
                        </Col>
                        <Col>9:45 PM - 12 AM</Col>
                      </Row>
                      <Row className='pt-3'>
                        <Col xs={12}>
                          <p>
                            The celebration will continue just a 3 minute walk
                            from The Tower. Late night bites will be provided to
                            accompany a cash bar. Come in your fancy pants or
                            change into something more comfortable.
                          </p>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={10}
                    md={10}
                    lg={4}
                    className='d-flex align-items-center'
                  >
                    <div className='ceromony-img'>
                      <img src={mjoImg} alt='' />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row />
            <Row className='py-2'>
              <Col sm={12} md={4} lg={{ span: 3 }}>
                <EventDate date={BRUNCH_DATE} title='brunch' />
              </Col>
              <Col md={8} lg={9}>
                <Row className='pb-2'>
                  <Col lg={8}>
                    <Row>
                      <Col className='bold' xs={4} sm={3} md={3} lg={3} xl={2}>
                        Location
                      </Col>
                      <Col>
                        <a
                          className='gold-link'
                          href={links.pressedLink}
                          target='_blanker'
                        >
                          Pressed Cafe, 105 Huntington Ave, Boston, MA 02199
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='bold' xs={4} sm={3} md={3} lg={3} xl={2}>
                        Time
                      </Col>
                      <Col>10:00 AM - 12:00 PM</Col>
                    </Row>
                    <Row className='pt-3'>
                      <Col xs={12}>
                        The newlyweds would love to see you one last time before
                        you go! A variety of hot and cold breakfast items will
                        be provided.
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Events;
