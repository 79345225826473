import React, { useMemo, useState, createContext, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

Auth.configure({ authenticationFlowType: 'USER_SRP_AUTH' });

const AuthProvider = ({ userData, children }) => {
  const [user, setUser] = useState(userData);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.fromSignIn) {
      navigate('guest/board');
    }
  }, [user]);

  const checkIsAdmin = (tUser) => {
    const groups =
      tUser?.signInUserSession.accessToken.payload['cognito:groups'];
    if (groups && groups.includes('admin')) {
      return true;
    }
    return false;
  };

  const eventSignIn = async ({ username, eventName, eventCode }) => {
    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
    const tmpUser = await Auth.signIn(username);
    await Auth.sendCustomChallengeAnswer(
      tmpUser,
      JSON.stringify({
        eventName,
        eventCode
      })
    );

    const { identityId } = await Auth.currentUserCredentials();
    const isAdmin = checkIsAdmin(tmpUser);
    setUser({
      fromLink: true,
      identityId,
      isAdmin,
      ...tmpUser
    });
  };

  const signIn = async ({ username, password }) => {
    Auth.configure({ authenticationFlowType: 'USER_SRP_AUTH' });
    const tmpUser = await Auth.signIn({
      username,
      password
    });
    const { identityId } = await Auth.currentUserCredentials();
    const isAdmin = checkIsAdmin(tmpUser);
    setUser({
      fromSignIn: true,
      identityId,
      isAdmin,
      ...tmpUser
    });
  };

  const signOut = async () => {
    await Auth.signOut();
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user,
      eventSignIn,
      signIn,
      signOut
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
