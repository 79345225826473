import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Badge, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Parking = (props) => {
  const { isIos } = props;
  const motorMartLink = isIos
    ? 'https://maps.apple.com/place?auid=15026654244963311182&lsp=9902'
    : 'https://goo.gl/maps/jNLTobF3qQ8buAHg6';

  const lazLink = isIos
    ? 'https://maps.apple.com/place?auid=1497947625672079810&lsp=9902'
    : 'https://goo.gl/maps/NcdHCWvs2U1WHbs57';

  return (
    <div id='parking-section' className='page-section'>
      <Container>
        <Row className='justify-content-center text-center'>
          <Col className='py-3' md={8}>
            A shuttle will be available to transport guests from the LAZ
            Arlington St. Parking Lot to the ceremony at Emmanuel Church.
            Following the ceremony, guests may take the shuttle or walk 7
            minutes to the reception at The Tower (see{' '}
            <Link className='gold-link' to='Events'>
              Events
            </Link>
            ).
          </Col>
        </Row>

        <Row>
          <Col xl={{ offset: 2, span: 4 }} md={6} sm={12}>
            <Card className='my-3'>
              <div style={{ position: 'relative' }}>
                <Badge
                  pill
                  style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    fontSize: '.9rem',
                    color: 'black'
                  }}
                  bg='warning'
                >
                  Shuttle Pick Up
                </Badge>
                <Card.Img src='https://d32slgyj4lxc3m.cloudfront.net/LAZ2.jpg' />
              </div>
              <Card.Body
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: '200px'
                }}
              >
                <Card.Title>LAZ Arlington St. Lot</Card.Title>
                <Row>
                  <Col>
                    Open-air lot. Adjacent to The Tower. Shuttle to Emmanuel
                    Church picks up at: 2:45, 3:05 pm.
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Card.Text>
                      <span
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        Rates
                      </span>
                      <br />
                      2-10 Hours: $25 <br />
                      10-24 Hours: $40 <br />
                    </Card.Text>
                  </Col>
                  <Col
                    xs={6}
                    style={{ display: 'flex', justifyContent: 'right' }}
                  >
                    <Card.Text>
                      <span
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        Location
                      </span>{' '}
                      <br />
                      <a href={lazLink} target='_blank' rel='noreferrer'>
                        109 Arlington St, Boston, MA 02116
                      </a>
                    </Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={4} md={6} sm={12}>
            <Card className='my-3'>
              <Card.Img src='https://d32slgyj4lxc3m.cloudfront.net/image0.jpeg' />
              <Card.Body
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: '200px'
                }}
              >
                <Card.Title>Motor Mart Garage</Card.Title>
                <Row>
                  <Col>
                    Covered parking. 7-min walk to Emmanuel Church, 5-min walk
                    to/from Arlington St. Lot and The Tower.
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Card.Text>
                      <span
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        Rates
                      </span>
                      <br />
                      2-10 Hours: $25 <br />
                      10-24 Hours: $40 <br />
                    </Card.Text>
                  </Col>
                  <Col
                    xs={6}
                    style={{ display: 'flex', justifyContent: 'right' }}
                  >
                    <Card.Text>
                      <span
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        Location
                      </span>{' '}
                      <br />
                      <a href={motorMartLink} target='_blank' rel='noreferrer'>
                        201 Stuart St. Boston, MA 02116
                      </a>
                    </Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Parking;
