import React from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import SectionTitle from 'Components/utility/section-title';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';

import { toMDYStr } from 'Utils/date-utils';
import useGallery from 'Hooks/useGallery';
// import useGalleryService from 'Hooks/useGalleryService';
import AdminGalleryControls from './components/admin-gallery-controls';

import 'Styles/gallery.scss';

// TODO:
// 1/ Error handling for PhotoGallery fetch errors
// 2/ Handle active vs inactive galleries

const PhotoGallery = () => {
  const { isAdmin } = useOutletContext();
  const { galleries, galleryError } = useGallery();

  const renderGalleryPreview = (gal) => {
    const thumbnailEls = gal.media.map((m) => (
      <Image key={m.thumbnailUrl} src={m.thumbnailUrl} alt='' />
    ));
    const placeholderText = gal.active
      ? 'Upload your photos now.'
      : 'Coming Soon';

    let eventDateStr = '';
    if (gal.eventDate) {
      eventDateStr = `- ${toMDYStr(gal.eventDate)}`;
    }

    return (
      <Card
        as={Link}
        to={gal.id}
        className='border-0 gallery-preview-card text-center'
      >
        <Card.Body className='justify-content-center '>
          {thumbnailEls.length > 2 ? (
            <div className='preview-thumbnail-wrapper'>{thumbnailEls}</div>
          ) : (
            <div className='thumbnail-placeholder'>{placeholderText}</div>
          )}
          <Card.Text as='h5' className='gallery-preview-name mt-3'>
            {gal.name}
          </Card.Text>
          <Card.Text>
            {gal.location} {eventDateStr}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  const renderGalleries = () =>
    galleries
      .filter((gallery) => gallery.active || isAdmin)
      .sort((a, b) => {
        if (a.eventDate && b.eventDate) {
          const aDate = Date.parse(a.eventDate);
          const bDate = Date.parse(b.eventDate);
          return aDate - bDate;
        }
        return Number(a.active) - Number(b.active);
      })
      .map((gal) => (
        <Col className='py-2' key={gal.name} xl={6} lg={10} md={12} sm={12}>
          {renderGalleryPreview(gal)}
        </Col>
      ));

  return (
    <Container>
      <Alert show={!!galleryError} className='my-4' variant='danger'>
        There is an error with this page. Please tell Ben to fix it.
      </Alert>
      <SectionTitle section='Gallery' topPadding={5} />
      <Row className='justify-content-center'>
        <Col md={10} xs={10}>
          <AdminGalleryControls modes={['add']} onCreateUpdate={() => {}} />
        </Col>
      </Row>

      <Row className='justify-content-center'>{renderGalleries()}</Row>
    </Container>
  );
};

export default PhotoGallery;
