import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import AdminCreateEditGalleryForm from './admin-create-edit-gallery-form';

// TODO:
// 1/ Implement delete
// 2/ Test Update Functionality

const AdminGalleryControls = ({ modes, onCreateUpdate, gallery }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { isAdmin } = useOutletContext();

  const handleCreateUpdate = () => {
    setShowCreateModal(false);
    onCreateUpdate();
  };

  const getButtons = () =>
    modes.map((mode) => {
      if (mode === 'add') {
        return (
          <Button key='add' onClick={() => setShowCreateModal(true)}>
            Add Gallery
          </Button>
        );
      }
      if (mode === 'update') {
        return (
          <Button key='update' onClick={() => setShowCreateModal(true)}>
            Edit Gallery
          </Button>
        );
      }

      return null;
    });

  return isAdmin ? (
    <>
      <Modal show={showCreateModal}>
        <Modal.Header>
          <h5>Create Gallery</h5>
        </Modal.Header>
        <Modal.Body>
          <AdminCreateEditGalleryForm
            gallery={gallery}
            onCreateUpdate={handleCreateUpdate}
            onFormClose={() => setShowCreateModal(false)}
          />
        </Modal.Body>
      </Modal>
      <Card className='mb-2'>
        <Card.Header>Admin Options</Card.Header>
        <Card.Body>
          <Row>
            <Col>{getButtons()}</Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  ) : null;
};

export default AdminGalleryControls;
