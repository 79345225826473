import { useState, useRef } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useAuth } from 'Hooks';

const useEventAuthorizer = () => {
  const [pendingEventSignIn, setPendingEventSignIn] = useState(false);
  const [eventSignInError, setEventSignInError] = useState();
  const { eventSignIn } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const protectRef = useRef();

  const getEventParams = () => {
    const eventCode = searchParams.get('eventCode');
    const eventName = searchParams.get('eventName');
    const username = searchParams.get('userType') || 'guest';
    return {
      eventCode,
      eventName,
      username
    };
  };

  const tryEventSignIn = async () => {
    if (protectRef.current) {
      return;
    }
    setPendingEventSignIn(true);
    protectRef.current = true;
    try {
      const { eventCode, eventName, username } = getEventParams();
      if (eventCode && eventName && username) {
        await eventSignIn({ username, eventName, eventCode });
        searchParams.delete('eventCode');
        searchParams.delete('eventName');
        setSearchParams(searchParams);
      } else if (eventCode || eventName) {
        setEventSignInError('Invalid sign In URL');
      }
    } catch (error) {
      setEventSignInError(
        'Error signing in. Please try entering the provided password.'
      );
    } finally {
      setPendingEventSignIn(false);
      protectRef.current = false;
    }
  };

  return {
    tryEventSignIn,
    eventSignInError,
    pendingEventSignIn
  };
};

export default useEventAuthorizer;
