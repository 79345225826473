import { createContext } from 'react';
import { API } from 'aws-amplify';

const API_NAME = 'adminApi';
const BASE_PATH = '/admin/guests';

export const AdminApiContext = createContext();

const useAdminApi = () => {
  const adminFetchAllGuests = async () => {
    try {
      const resp = await API.get(API_NAME, BASE_PATH);
      return resp;
    } catch (err) {
      console.error(err?.response?.data?.error);
      return null;
    }
  };

  return {
    adminFetchAllGuests
  };
};

export default useAdminApi;
