import React from 'react';

import { Auth } from 'aws-amplify';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import HomePage from 'Components/pages/home-page';
import { GuestProvider } from 'Context/guest-context';
import Guides from 'Components/guides';
import ErrorBoundary from 'Components/error-boundary';
import { AppContextProvider } from 'Context/app-context';
import Layout from 'Components/layout';
import Travel from 'Components/pages/travel';
import Faq from 'Components/pages/faq';
import GuestRsvpPage from 'Components/pages/rsvp';
import GuestPages from 'Components/pages/guest-pages';
import Admin from 'Components/pages/admin';
import PhotoGallery from 'Components/pages/guest-pages/photo-gallery';
import EventGallery from 'Components/pages/guest-pages/event-gallery';

import 'Styles/App.scss';
import TestPage from 'Components/pages/guest-pages/test-page';

library.add(fab, faFacebookF, faCircleCheck, faUpRightFromSquare);

const router = createBrowserRouter([
  {
    element: <Layout />,
    loader: async () => {
      try {
        const tmpUser = await Auth.currentAuthenticatedUser();
        const { identityId } = await Auth.currentUserCredentials();
        const groups =
          tmpUser?.signInUserSession.accessToken.payload['cognito:groups'];
        const isAdmin = groups && groups.includes('admin');
        return { fromSignIn: false, identityId, isAdmin, ...tmpUser };
      } catch (error) {
        return null;
      }
    },
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/rsvp',
        element: <GuestRsvpPage />
      },
      {
        path: '/faq',
        element: <Faq />
      },
      {
        path: '/travel',
        element: <Travel />
      },
      {
        path: '/admin',
        element: <Admin />
      },
      {
        path: '/login',
        element: <Navigate to='/' replace state={{ showLoginModal: true }} />
      },
      {
        path: '/guest',
        element: <GuestPages />,
        children: [
          {
            path: '',
            element: <Navigate to='board' replace />
          },
          {
            path: 'gallery',
            element: <PhotoGallery />
          },
          {
            path: 'gallery/:galleryId',
            element: <EventGallery />
          },
          {
            path: 'test',
            element: <TestPage />
          },
          {
            path: '*',
            element: <Navigate to='gallery' replace />
          }
        ]
      },
      {
        path: '/guides/:guideId',
        element: <Guides />
      },
      {
        path: '*',
        element: <ErrorBoundary />
      }
    ]
  }
]);

const App = () => (
  <div className='App'>
    <AppContextProvider>
      <GuestProvider>
        <RouterProvider router={router} />
      </GuestProvider>
    </AppContextProvider>
  </div>
);

export default App;
