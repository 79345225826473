import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useSearchParams } from 'react-router-dom';

import ConfirmationCodeInput from 'Components/form/confirmation-code-input';
import { useGuests } from 'Hooks';

const GuestAccessChallenge = ({ onSuccess }) => {
  const [searchParams] = useSearchParams();
  const [guestId, setGuestId] = useState();
  const [lastName, setLastName] = useState('');
  const [isFetchingGuestData, setIsFetchingGuestData] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const { fetchGuests } = useGuests();

  const numDigits = 5;

  const validateGuestIdParam = (param) => {
    if (param) {
      const tmp = Number(param);
      return Number.isInteger(tmp) && param.length === numDigits;
    }
    return undefined;
  };
  useEffect(() => {
    const guestIdParam = searchParams.get('guestid');
    if (validateGuestIdParam(guestIdParam)) {
      setGuestId(guestIdParam);
    }
  }, []);

  const submitCode = (code) => {
    setRequestError(null);
    setGuestId(code);
  };

  const prompt = (
    <Card.Text className='text-center fs-6 text-muted'>
      Enter your invite code
    </Card.Text>
  );

  const lastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const fetchUser = async () => {
    try {
      setIsFetchingGuestData(true);
      const guestsTmp = await fetchGuests(guestId, lastName);
      if (guestsTmp) {
        onSuccess();
        setIsFetchingGuestData(false);
      }
    } catch (err) {
      console.error(err);
      setGuestId(null);
      setLastName('');
      setRequestError(err);
      setIsFetchingGuestData(false);
    }
  };
  const handleKeyDown = (e) => {
    if (lastName && lastName.length > 3) {
      if (e.key === 'Enter') {
        fetchUser();
      }
    }
  };

  const stepOne = (
    <div>
      {!guestId ? (
        <Container fluid>
          <Row>
            <Col className='px-0'>
              <ConfirmationCodeInput
                label='Guest ID'
                numDigits={numDigits}
                submit={submitCode}
                prefill={guestId}
                setFocus
              />
            </Col>
          </Row>
          <Row>
            <Col>{prompt}</Col>
          </Row>
        </Container>
      ) : (
        <Container fluid>
          <Row>
            <Col className='px-0'>
              <FloatingLabel
                controlId='floatingInput'
                label='Last name'
                className='mb-3'
              >
                <Form.Control
                  placeholder='Marino'
                  type='text'
                  value={lastName}
                  pattern='[a-zA-Z]+'
                  onKeyDown={handleKeyDown}
                  onChange={lastNameChange}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className='mt-3 float-start'
                onClick={() => setGuestId('')}
                variant='secondary'
                color='white'
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button
                className='mt-3 float-end'
                disabled={lastName.length < 3}
                onClick={fetchUser}
              >
                Next
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );

  return isFetchingGuestData ? (
    <div className='text-center'>
      <Spinner animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  ) : (
    <Card className='access-challenge-card align-items-center mb-4'>
      <Card.Body>
        <Card.Text className='text-center text-danger'>
          {requestError}
        </Card.Text>
        {stepOne}
      </Card.Body>
    </Card>
  );
};

export default GuestAccessChallenge;
