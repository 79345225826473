const getDateObj = (v) => {
  if (typeof v === 'string' || typeof v === 'number') return new Date(v);
  return v;
};

export const getMonthName = (val, shortOrLong) => {
  const date = getDateObj(val);
  if (typeof date.getMonth !== 'function') {
    throw new Error(`Illegal Type from ${val}; expected string or date`);
  }
  return date.toLocaleDateString('en-US', { month: shortOrLong });
};

export const getDayName = (val, shortOrLong) => {
  const date = getDateObj(val);
  if (typeof date.getMonth !== 'function') {
    throw new Error(`Illegal Type from ${val}; expected string or date`);
  }
  return date.toLocaleDateString('en-US', { weekday: shortOrLong });
};

export const getDayStr = (val) => {
  const date = getDateObj(val);
  if (typeof date.getMonth !== 'function') {
    throw new Error(`Illegal Type from ${val}; expected string or date`);
  }
  return date.toLocaleDateString('en-US', { day: '2-digit' });
};

export const hasDatePassed = (val) => {
  const date = getDateObj(val);
  const curr = new Date();
  return date.getTime() <= curr.getTime();
};

export const getTime = (val) => getDateObj(val).getTime();

export const toMDYStr = (val) => getDateObj(val).toLocaleDateString('en-US');
