/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useMemo, useState } from 'react';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [showSplash, setShowSplash] = useState(true);

  const splashShown = () => {
    setShowSplash(false);
  };
  useEffect(() => {
    // const qParams = window.location.search.slice(1);
    const [_, query] = window.location.href.split('?');
    const params = new URLSearchParams(query);
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    const url = new URL(document.URL || window.location.href);
    if (url.pathname !== '/' || params.has('nosplash')) {
      setShowSplash(false);
    }
  }, []);

  const checkIos = () => {
    const platform = navigator?.userAgent || navigator?.platform || 'unknown';
    return /iPhone|iPod|iPad/.test(platform);
  };
  const isIos = useMemo(() => checkIos(), [navigator]);

  return (
    <AppContext.Provider value={{ isIos, showSplash, splashShown }}>
      {children}
    </AppContext.Provider>
  );
};
