import React, { useEffect, useState, useContext } from 'react';

import { useLocation } from 'react-router-dom';
import { AppContext } from 'Context/app-context';
import Splash from '../../splash';
import Crew from './sections/crew';
import Events from './sections/events';
import Hero from './sections/hero';
import Story from './sections/story';
import Welcome from './sections/welcome';

const HomePage = () => {
  const { hash } = useLocation();
  const [heroHeight, setHeroHeight] = useState();
  const { showSplash } = useContext(AppContext);

  useEffect(() => {
    const cdEl = document.getElementById('countdown');
    const menuEl = document.getElementById('menu');
    const heightdiff =
      cdEl.getBoundingClientRect().height +
      menuEl.getBoundingClientRect().height;

    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    setHeroHeight(vh - heightdiff);
  });

  useEffect(() => {
    const [trimmedHash] = hash.split('?');
    if (trimmedHash) {
      const el = document.getElementById(trimmedHash.slice(1));
      if (!showSplash && el) {
        setTimeout(() => {
          el.scrollIntoView({ behavior: 'instant' });
        }, 100);
      }
    }
  }, [hash, showSplash]);
  return (
    <div className='home'>
      <Splash />
      <Hero imgHeight={heroHeight} />

      <Story />
      <div style={{ height: '600px' }}>
        <Welcome />
      </div>
      <Events />
      <Crew />
    </div>
  );
};

export default HomePage;
