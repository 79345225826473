import React from 'react';
import {
  Outlet,
  ScrollRestoration,
  useLoaderData,
  useMatch
} from 'react-router-dom';

import AuthProvider from 'Context/auth-context';
import Menu from './menu';
import Footer from './footer';

const Layout = () => {
  const user = useLoaderData();
  const isGuestPage = useMatch('/guest/*');
  // const [searchParams] = useSearchParams();

  return (
    <AuthProvider userData={user}>
      <div className='d-flex flex-column page-layout'>
        <Menu />
        <Outlet />
        <ScrollRestoration />
        {isGuestPage ? null : <Footer />}
      </div>
    </AuthProvider>
  );
};

export default Layout;
