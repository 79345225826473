import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import SectionTitle from 'Components/utility/section-title';
import GuestAccessChallenge from './guest-access-challenge';
import RsvpPage from './rsvp';
import { useGuests } from '../../../Hooks';

import 'Styles/guest-registration.scss';

const GuestRsvpPage = () => {
  const [loadingGuests, setLoadingGuests] = useState(false);
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const { guests, numResponses } = useGuests();

  const onSuccessChallenge = () => {
    setLoadingGuests(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  });
  useEffect(() => {
    if (loadingGuests) {
      if (guests && guests.length > 0) {
        setLoadingGuests(false);
      }
    }
    if (guests && guests.length > 0 && guests.length === numResponses) {
      setShowThankYouDialog(true);
    }
  }, [guests, loadingGuests, numResponses]);

  const hideCardBorder = () =>
    !showThankYouDialog && guests && guests.length > 0;

  const renderRsvpDialog = () => {
    if (showThankYouDialog) {
      return (
        <Card className={hideCardBorder() ? 'no-border' : ''}>
          <Card.Body>
            <Card.Title className='pb-3'>
              Thank you for submitting your RSVP.
            </Card.Title>
            <Card.Text className='pb-4'>
              You can use your access code to edit your submission until{' '}
              <span style={{ fontWeight: 'bold' }}> October 1</span>.
            </Card.Text>
            <div className='d-grid gap-2'>
              <Button
                variant='primary'
                onClick={() => setShowThankYouDialog(false)}
                className='btn-block'
              >
                Edit
              </Button>
            </div>
          </Card.Body>
        </Card>
      );
    }
    return <RsvpPage />;
  };

  return (
    <div
      style={{ flex: 1, height: '100%' }}
      className='d-flex flex-column page-section'
    >
      <SectionTitle section='RSVP' />
      <Container style={{ flex: 1 }}>
        {guests?.length === 0 && !loadingGuests ? (
          <Row xs={1} className='py-2 mx-2 justify-content-center'>
            <Col className='mx-auto text-center mx-2'>
              <p>
                Your RSVP contains your invite code. Contact Ben or Victoria if
                you need assistance.
              </p>
            </Col>
            <Col className='d-flex justify-content-center'>
              <GuestAccessChallenge onSuccess={onSuccessChallenge} />
            </Col>
          </Row>
        ) : (
          <Row
            xs={1}
            className='my-6 justify-content-center align-items-center py-0 mx-0'
          >
            <Col
              className='d-flex justify-content-center align-items-center px-0 mx-0'
              md={12}
              xl={6}
              lg={8}
            >
              {renderRsvpDialog()}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default GuestRsvpPage;
