import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { createGallery, updateGallery } from 'graphql/mutations';
import UserSubMap from '../types/user-sub-map';

// TODO:
// 1/ Test Update Functionality
// 2/ Add loading/pending UX

const AdminCreateEditGalleryForm = ({
  gallery,
  onFormClose,
  onCreateUpdate
}) => {
  const [formValues, setFormValues] = useState({
    name: '',
    groups: {
      guest: false,
      bridesmaid: false,
      groomsman: false,
      photobooth: false
    },
    active: false,
    location: '',
    eventDate: '2023-11-10'
  });
  const [createFormValidated, setCreateFormValidated] = useState(false);
  // const [pendingSubmit, setPendingSubmit] = useState(false);
  const [serverError, setServerError] = useState();

  const handleCreateGalleryFormChange = (values) => {
    setCreateFormValidated(false);
    setFormValues({ ...formValues, ...values });
  };

  const isEdit = !!gallery;

  useEffect(() => {
    if (gallery) {
      let eventDateStr = '';
      if (gallery.eventDate) {
        [eventDateStr] = gallery.eventDate.split('T');
      }
      setFormValues({
        name: gallery.name,
        groups: {
          guest: gallery.galleryUsers.includes(UserSubMap.nameToSub.guest),
          bridesmaid: gallery.galleryUsers.includes(
            UserSubMap.nameToSub.bridesmaid
          ),
          groomsman: gallery.galleryUsers.includes(
            UserSubMap.nameToSub.groomsman
          ),
          photobooth: gallery.galleryUsers.includes(
            UserSubMap.nameToSub.photobooth
          )
        },
        active: gallery.active,
        location: gallery.location,
        eventDate: eventDateStr
      });
    }
  }, []);

  const callUpdateGallery = async (data) =>
    API.graphql(graphqlOperation(updateGallery, { input: data }));

  const callCreateGallery = async (data) =>
    API.graphql(graphqlOperation(createGallery, { input: data }));

  const handleClose = (cb) => {
    if (cb) {
      cb();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setCreateFormValidated(true);
    } else {
      const galleryUsers = Object.entries(formValues.groups)
        .map(([key, value]) => {
          if (value) {
            return UserSubMap.nameToSub[key];
          }
          return '';
        })
        .filter((v) => !!v);

      const data = {
        name: formValues.name,
        active: formValues.active,
        location: formValues.location,
        eventDate: `${formValues.eventDate}T12:00:00.000Z`,
        galleryUsers
      };

      try {
        let resp = null;
        if (isEdit) {
          resp = await callUpdateGallery({
            id: gallery.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: gallery._version,
            ...data
          });
        } else {
          resp = await callCreateGallery(data);
        }
        if (resp) {
          handleClose(onCreateUpdate);
        }
      } catch (err) {
        console.error('Error creating gallery', err);
        setServerError(err);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit} noValidate validated={createFormValidated}>
      {serverError ? <Alert variant='danger'>serverError</Alert> : null}
      <Row className='py-3'>
        <Col>
          <Form.Group>
            <FloatingLabel controlId='galleryName' label='Name'>
              <Form.Control
                value={formValues.name}
                placeholder='My Gallery'
                onChange={({ target }) =>
                  handleCreateGalleryFormChange({ name: target.value })
                }
                required
              />
              <Form.Control.Feedback type='invalid'>
                Name is required
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col>
          <Form.Check
            inline
            label='Guest'
            checked={formValues.groups.guest || false}
            onChange={({ target }) =>
              handleCreateGalleryFormChange({
                groups: {
                  ...formValues.groups,
                  guest: target.checked
                }
              })
            }
            name='group-guest'
            type='checkbox'
          />
          <Form.Check
            inline
            label='Bridesmaid'
            name='group-bridesmaid'
            type='checkbox'
            checked={formValues.groups.bridesmaid || false}
            onChange={({ target }) =>
              handleCreateGalleryFormChange({
                groups: {
                  ...formValues.groups,
                  bridesmaid: target.checked
                }
              })
            }
          />
          <Form.Check
            inline
            label='Groomsmen'
            name='group-groomsmen'
            type='checkbox'
            checked={formValues.groups.groomsman || false}
            onChange={({ target }) =>
              handleCreateGalleryFormChange({
                groups: {
                  ...formValues.groups,
                  groomsman: target.checked
                }
              })
            }
          />
          <Form.Check
            inline
            label='Photobooth'
            name='group-photobooth'
            type='checkbox'
            checked={formValues.groups.photobooth || false}
            onChange={({ target }) =>
              handleCreateGalleryFormChange({
                groups: {
                  ...formValues.groups,
                  photobooth: target.checked
                }
              })
            }
          />
        </Col>
      </Row>
      <Row className='py-3'>
        <Col>
          <Form.Check
            type='switch'
            label='Active'
            checked={formValues.active || false}
            onChange={({ target }) =>
              handleCreateGalleryFormChange({
                active: target.checked
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <FloatingLabel controlId='galleryLocation' label='Location'>
              <Form.Control
                value={formValues.location}
                placeholder='My Gallery'
                onChange={({ target }) =>
                  handleCreateGalleryFormChange({ location: target.value })
                }
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <FloatingLabel controlId='galleryEventDate' label='Date'>
              <Form.Control
                type='date'
                value={formValues.eventDate}
                placeholder='10/11/2023'
                onChange={({ target }) =>
                  handleCreateGalleryFormChange({
                    eventDate: target.value
                  })
                }
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>
      <Row className='pt-5'>
        <Col className='float-start'>
          <Button variant='secondary' onClick={() => handleClose(onFormClose)}>
            Close
          </Button>
        </Col>
        <Col className='d-flex float-end justify-content-end'>
          <Button type='submit'>{isEdit ? 'Update' : 'Create'}</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminCreateEditGalleryForm;
