/* eslint-disable object-curly-newline */
import React from 'react';

import CountDown from 'react-countdown';
import 'Styles/countdown.scss';

const Countdown = () => {
  const renderer = ({ days, hours, minutes, seconds }) => (
    // Render a countdown
    <>
      <div className='time-block'>
        <p className='value'>{days}</p>
        <p className='label'>Days</p>
      </div>
      <span className='divider'>:</span>
      <div className='time-block'>
        <p className='value'>{hours < 10 ? `0${hours}` : hours}</p>
        <p className='label'>Hours</p>
      </div>
      <span className='divider'>:</span>
      <div className='time-block'>
        <p className='value'>{minutes < 10 ? `0${minutes}` : minutes}</p>
        <p className='label'>Mins</p>
      </div>
      <span className='divider'>:</span>
      <div className='time-block'>
        <p className='value'>{seconds < 10 ? `0${seconds}` : seconds}</p>
        <p className='label'>Secs</p>
      </div>
    </>
  );
  return (
    <div
      id='countdown'
      className='clock-area d-flex justify-content-center align-items-center'
    >
      <CountDown
        className='text-center'
        renderer={renderer}
        date='November 10, 2023'
      />
    </div>
  );
};

export default Countdown;
