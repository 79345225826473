import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

const ConfirmationCodeInput = ({
  numDigits,
  submit,
  prefill,
  locked,
  setFocus
}) => {
  const [values, setValues] = useState(new Array(numDigits).fill(''));

  useEffect(() => {
    const arr = Array.from(String(prefill), Number);
    if (arr.length === numDigits) {
      setValues(arr);
    } else if (setFocus) {
      const firstField = document.querySelector('input[name=field-0]');
      firstField?.focus();
    }
  }, [prefill, numDigits]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    // eslint-disable-next-line no-unused-vars
    const [_, fieldIndex] = name.split('-');
    const fieldIntIndex = parseInt(fieldIndex, 10);
    const tmpValues = values;
    const intVal = parseInt(value, 10);

    if (!Number.isNaN(intVal)) {
      if (value.length === 2) {
        tmpValues[fieldIndex] =
          value.charAt(0) === tmpValues[fieldIndex]
            ? value.charAt(1)
            : value.charAt(0);
      } else {
        tmpValues[fieldIndex] = value;
      }

      setValues([...tmpValues]);
      if (fieldIntIndex < numDigits - 1) {
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        );
        if (nextfield !== null) {
          nextfield.focus();
        }
      } else {
        const foundEmptyIdx = tmpValues.findIndex((el) => el === '');
        if (foundEmptyIdx === -1) {
          const strCode = values.join('');
          document.querySelector(`input[name=field-${fieldIntIndex}`).blur();
          submit(strCode);
        } else {
          const nextfield = document.querySelector(
            `input[name=field-${foundEmptyIdx}]`
          );
          nextfield.focus();
        }
      }
    } else if (!value) {
      tmpValues[fieldIndex] = '';
      setValues([...tmpValues]);
    }
  };
  const createInputs = () => {
    const array = new Array(numDigits).fill('');
    return array.map((v, idx) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        className='code-confirmation me-1'
        style={{ display: 'inline-block' }}
      >
        <Form.Control
          className='mb-2 code-confirmation'
          name={`field-${idx}`}
          value={values[idx]}
          type='number'
          disabled={locked}
          onChange={handleChange}
        />
      </div>
    ));
  };
  return <div className='text-center'>{createInputs()}</div>;
};

export default ConfirmationCodeInput;
