/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateGallery = /* GraphQL */ `
  subscription OnCreateGallery($filter: ModelSubscriptionGalleryFilterInput) {
    onCreateGallery(filter: $filter) {
      id
      name
      galleryUsers
      media {
        items {
          id
          galleryId
          name
          createdAt
          contentType
          sizeBytes
          mediaType
          status
          src
          fileExt
          thumbnailSrc
          pendingUploadTimeout
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      active
      location
      eventDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateGallery = /* GraphQL */ `
  subscription OnUpdateGallery($filter: ModelSubscriptionGalleryFilterInput) {
    onUpdateGallery(filter: $filter) {
      id
      name
      galleryUsers
      media {
        items {
          id
          galleryId
          name
          createdAt
          contentType
          sizeBytes
          mediaType
          status
          src
          fileExt
          thumbnailSrc
          pendingUploadTimeout
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      active
      location
      eventDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteGallery = /* GraphQL */ `
  subscription OnDeleteGallery($filter: ModelSubscriptionGalleryFilterInput) {
    onDeleteGallery(filter: $filter) {
      id
      name
      galleryUsers
      media {
        items {
          id
          galleryId
          name
          createdAt
          contentType
          sizeBytes
          mediaType
          status
          src
          fileExt
          thumbnailSrc
          pendingUploadTimeout
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      active
      location
      eventDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMediaItem = /* GraphQL */ `
  subscription OnCreateMediaItem(
    $filter: ModelSubscriptionMediaItemFilterInput
    $owner: String
  ) {
    onCreateMediaItem(filter: $filter, owner: $owner) {
      id
      galleryId
      name
      createdAt
      contentType
      sizeBytes
      mediaType
      status
      src
      fileExt
      thumbnailSrc
      uploadUrl {
        url
        fields
        __typename
      }
      pendingUploadTimeout
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateMediaItem = /* GraphQL */ `
  subscription OnUpdateMediaItem(
    $filter: ModelSubscriptionMediaItemFilterInput
    $owner: String
  ) {
    onUpdateMediaItem(filter: $filter, owner: $owner) {
      id
      galleryId
      name
      createdAt
      contentType
      sizeBytes
      mediaType
      status
      src
      fileExt
      thumbnailSrc
      uploadUrl {
        url
        fields
        __typename
      }
      pendingUploadTimeout
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteMediaItem = /* GraphQL */ `
  subscription OnDeleteMediaItem(
    $filter: ModelSubscriptionMediaItemFilterInput
    $owner: String
  ) {
    onDeleteMediaItem(filter: $filter, owner: $owner) {
      id
      galleryId
      name
      createdAt
      contentType
      sizeBytes
      mediaType
      status
      src
      fileExt
      thumbnailSrc
      uploadUrl {
        url
        fields
        __typename
      }
      pendingUploadTimeout
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
