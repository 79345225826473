import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import SectionTitle from 'Components/utility/section-title';

import 'Styles/faq.scss';

const QAs = [
  {
    question: 'What should I wear?',
    answer: (
      <p>
        Formal attire and dancing shoes! We suggest a suit, full length evening
        dress, dressy jumpsuit, or extra fabulous cocktail dress.
      </p>
    )
  },
  {
    question: 'Where should I park?',
    answer: (
      <p>
        Guests staying in the Greater Boston area (e.g., Somerville, Medford,
        Watertown) can park in one of Boston&apos;s many garages. We recommend
        one of two locations described under{' '}
        <Link className='gold-link' to='/travel'>
          Travel &amp; Stay
        </Link>
        . Guests staying in Downtown Boston can park overnight at these
        locations and at select hotels. However, we recommend that guests
        staying in the city forgo car rentals in favor of using Boston&apos;s
        public transportation and ride services, as driving and parking in the
        city can be difficult and costly.
      </p>
    )
  },
  {
    question: 'Can I bring my children or a plus one?',
    answer: (
      <p>
        {' '}
        We are able to accommodate only those guests listed on your invitation.{' '}
      </p>
    )
  },
  {
    question: 'Can I take and post pictures?',
    answer: (
      <p>
        We <span style={{ fontStyle: 'italic' }}>really</span> want to see your
        faces, not your devices! So,{' '}
        <span style={{ fontWeight: 'bold' }}>
          please, refrain from using cell phones and cameras until after the
          ceremony{' '}
        </span>{' '}
        &ndash; then, snap and post away! We would love for you to share your
        reception photos with the hashtag #4ThousandMilesToTheAisle. In return,
        we pinky promise to share all the beautiful ceremony photos captured by
        our photographers.
      </p>
    )
  },
  {
    question: 'Do you have a hotel block for guests?',
    answer: (
      <p>
        Several! Please see{' '}
        <Link className='gold-link' to='/travel'>
          Travel &amp; Stay
        </Link>{' '}
        for options.
      </p>
    )
  },
  {
    question:
      'Will there be any vegan/vegetarian/gluten free/special diet options?',
    answer: (
      <p>
        Each guest will choose between a plated chicken, beef, or vegetarian
        entree and indicate allergies/dietary restrictions when submitting the
        RSVP. The chef and waitstaff will carefully handle all allergies. Please
        contact the bride or groom{' '}
        <span style={{ fontWeight: 'bold' }}> by Oct 1, 2023 </span>
        with allergy-related questions or concerns.
      </p>
    )
  },
  {
    question: 'Will alcohol be served?',
    answer: (
      <p> Our reception will have an open bar with beer, wine, and liquor. </p>
    )
  },
  {
    question: 'Will transportation be offered?',
    answer: (
      <p>
        A shuttle will be provided from LAZ Arlington St. Parking Lot to
        Emmanuel Church, and from Emmanuel Church to The Tower. Please see{' '}
        <Link className='gold-link' to='/travel'>
          Travel &amp; Stay
        </Link>{' '}
        for shuttle times and details.
      </p>
    )
  },
  {
    question: 'I still have questions. Who can I ask?',
    answer: (
      <p>
        Please call, text, or email the bride and groom at
        marinocolbert@gmail.com. We kindly ask that you contact us with any
        questions{' '}
        <span style={{ fontWeight: 'bold' }}> before November 1st.</span>
      </p>
    )
  }
];
const Faq = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  });

  return (
    <div id='faq' className='page-section pb-5'>
      <SectionTitle section='FAQ' />
      <Container className='container-sm '>
        {QAs.map((item) => (
          <Row key={item.question} style={{ marginTop: '' }} className=''>
            <Col xs={12}>
              <Card className='faq-card text-center' style={{ border: 'none' }}>
                <Card.Body>
                  <Card.Title>{item.question}</Card.Title>
                  <div>{item.answer}</div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

export default Faq;
