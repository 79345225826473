/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { useForm, Controller } from 'react-hook-form';

import { useAuth } from 'Hooks';

const Login = ({ onHide }) => {
  const { signIn } = useAuth();

  const [serverError, setServerError] = useState();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors }
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await signIn(data);
      setServerError();
      onHide();
    } catch (e) {
      setServerError('Invalid Credentials');
    }
  };

  return (
    <div>
      <Card
        style={{ border: 'none' }}
        className='mb-4  justify-content-center  justify-content-md-center'
      >
        <Card.Body>
          <div className='mb-3'>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className='mb-3' controlId='loginFormUsername'>
                <Controller
                  control={control}
                  name='username'
                  defaultValue='guest'
                  rules={{
                    required: { value: true, message: 'Required' }
                  }}
                  render={({ field: { onChange, ref } }) => (
                    <Form.Select
                      onChange={onChange}
                      defaultValue='guest'
                      ref={ref}
                      isInvalid={errors.username}
                    >
                      <option value='guest'>Guest</option>
                      <option value='bridesmaid'>Bridesmaid</option>
                      <option value='groomsman'>Groomsman</option>
                      <option value='admin'>Admin</option>
                    </Form.Select>
                  )}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.username?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3' controlId='loginFormPassword'>
                <FloatingLabel
                  controlId='loginFormPassword'
                  label='Password'
                  className='mb-3'
                >
                  <Controller
                    control={control}
                    name='password'
                    defaultValue=''
                    rules={{
                      required: { value: true, message: 'Required' },
                      minLength: {
                        value: 5,
                        message: 'Password must be 5 characters'
                      }
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Form.Control
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        type='password'
                        isInvalid={errors.password}
                        placeholder='Enter password'
                      />
                    )}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.password?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              {serverError ? (
                <Alert variant='danger'>{serverError} </Alert>
              ) : null}

              <div className='d-grid gap-2'>
                <Button
                  type='submit'
                  disabled={isSubmitting}
                  variant='primary'
                  className='btn-block'
                >
                  {isSubmitting ? (
                    <span className='spinner-border spinner-border-sm mr-1' />
                  ) : (
                    'Log In'
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </Card.Body>
        <Card.Text className='text-center '>
          Access to these pages are restricted to attending event guests. Please
          scan the QR code at your event table or entering the password provided
          by Victoria and Ben.
        </Card.Text>
      </Card>
    </div>
  );
};

export default Login;
