/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGallery = /* GraphQL */ `
  mutation CreateGallery(
    $input: CreateGalleryInput!
    $condition: ModelGalleryConditionInput
  ) {
    createGallery(input: $input, condition: $condition) {
      id
      name
      galleryUsers
      media {
        items {
          id
          galleryId
          name
          createdAt
          contentType
          sizeBytes
          mediaType
          status
          src
          fileExt
          thumbnailSrc
          pendingUploadTimeout
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      active
      location
      eventDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGallery = /* GraphQL */ `
  mutation UpdateGallery(
    $input: UpdateGalleryInput!
    $condition: ModelGalleryConditionInput
  ) {
    updateGallery(input: $input, condition: $condition) {
      id
      name
      galleryUsers
      media {
        items {
          id
          galleryId
          name
          createdAt
          contentType
          sizeBytes
          mediaType
          status
          src
          fileExt
          thumbnailSrc
          pendingUploadTimeout
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      active
      location
      eventDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGallery = /* GraphQL */ `
  mutation DeleteGallery(
    $input: DeleteGalleryInput!
    $condition: ModelGalleryConditionInput
  ) {
    deleteGallery(input: $input, condition: $condition) {
      id
      name
      galleryUsers
      media {
        items {
          id
          galleryId
          name
          createdAt
          contentType
          sizeBytes
          mediaType
          status
          src
          fileExt
          thumbnailSrc
          pendingUploadTimeout
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      active
      location
      eventDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMediaItem = /* GraphQL */ `
  mutation CreateMediaItem(
    $input: CreateMediaItemInput!
    $condition: ModelMediaItemConditionInput
  ) {
    createMediaItem(input: $input, condition: $condition) {
      id
      galleryId
      name
      createdAt
      contentType
      sizeBytes
      mediaType
      status
      src
      fileExt
      thumbnailSrc
      uploadUrl {
        url
        fields
        __typename
      }
      pendingUploadTimeout
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateMediaItem = /* GraphQL */ `
  mutation UpdateMediaItem(
    $input: UpdateMediaItemInput!
    $condition: ModelMediaItemConditionInput
  ) {
    updateMediaItem(input: $input, condition: $condition) {
      id
      galleryId
      name
      createdAt
      contentType
      sizeBytes
      mediaType
      status
      src
      fileExt
      thumbnailSrc
      uploadUrl {
        url
        fields
        __typename
      }
      pendingUploadTimeout
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteMediaItem = /* GraphQL */ `
  mutation DeleteMediaItem(
    $input: DeleteMediaItemInput!
    $condition: ModelMediaItemConditionInput
  ) {
    deleteMediaItem(input: $input, condition: $condition) {
      id
      galleryId
      name
      createdAt
      contentType
      sizeBytes
      mediaType
      status
      src
      fileExt
      thumbnailSrc
      uploadUrl {
        url
        fields
        __typename
      }
      pendingUploadTimeout
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
